import {addDoc, collection} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import {db} from '../firebaseConfig';
import {Link, useNavigate} from 'react-router-dom';
import { setCalendar } from '../store/reducers/loaderReducer';
import { useAppDispatch } from '../helpers';

export const FormPage = () => {
    const [sent, setSent] = useState(false);
    const dispatch = useAppDispatch();

    const sendMail = () => {
        setSent(true);
    };

    return (
        <div className='formpage'>
            <div className='signup-container'>
                <div className='title-container'>
                    <div className='signup-title-one'>Sign Up for</div>
                    <div className='signup-title-two'>Early Access Now</div>
                </div>
                <div className='description-container'>
                    <div>
                        Sign up for Early Access or <span className={'desc-register link'} onClick={() => dispatch(setCalendar(true))}>book a 30-minute consultation</span> to get a front-row seat to watch our
                        product evolve and early access to BlueQuail’s 60+ AI for business use cases.
                        Follow us on <a href='https://www.linkedin.com/company/qedsoftware' className={'desc-register link'} target='_blank'>LinkedIn</a> to learn more about approachable AI for business.

                    </div>
                    <div style={{color:'var(--orange)'}}> And remember: AI don't bite!</div>
                    <div className='desc-note'>
                        Note: We value your privacy and will only use the information
                        provided for updates regarding our product. Join us as we work
                        towards changing the landscape of AI in business. Sign up for early
                        access today.
                    </div>
                </div>
            </div>
            <section className='form-container' id='form'>
                <div className='form'>
                    {sent ? <CompletedForm/> : <Form onSend={sendMail}/>}
                </div>
            </section>
        </div>
    );
};

interface IFormProps {
    onSend: Function;
}

const Form = (props: IFormProps) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [key, setKey] = useState('');
    const navigate = useNavigate();
    const [policy, setPolicy] = useState(false);
    const [emailCheckbox, setEmailCheckbox] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailCheckboxError, setEmailCheckboxError] = useState(false);
    const [policyError, setPolicyError] = useState(false);
    const handleNameValid = () => {
        if (name.length < 1) {
            setNameError(true);
            return true;
        } else {
            setNameError(false);
            return false;
        }
    }
    const handleEmailValid = () => {
        if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email)) {
            setEmailError(true);
            return true;
        } else {
            setEmailError(false);
            return false
        }
    }
    const handleEmailCheckboxValid = () => {
        if (!emailCheckbox) {
            setEmailCheckboxError(true);
            return true;
        } else {
            setEmailCheckboxError(false);
            return false;
        }
    }
    const handlePolicyValid = () => {
        if (!policy) {
            setPolicyError(true);
            return true;
        } else {
            setPolicyError(false);
            return false;
        }
    }
    const handleEmailCheckboxChange = (e: any) => {
        setEmailCheckbox(e.target.checked);
        if (!e.target.checked) {
            setEmailCheckboxError(true);
            return true;
        } else {
            setEmailCheckboxError(false);
            return false;
        }
    }
    const handlePolicyChange = (e: any) => {
        setPolicy(e.target.checked);
        if (!e.target.checked) {
            setPolicyError(true);
            return true;
        } else {
            setPolicyError(false);
            return false;
        }
    }


    const handleLoaded = () => {
        //@ts-ignore
        window.grecaptcha.ready((_) => {
            //@ts-ignore
            window.grecaptcha
                .execute('6LfGLiMnAAAAAAuZCGUuZEW1m9VF7D0yk7QHe9b5', {
                    action: 'homepage',
                })
                .then(() => {
                });
        });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://www.google.com/recaptcha/api.js?render=6LfGLiMnAAAAAAuZCGUuZEW1m9VF7D0yk7QHe9b5';
        document.body.appendChild(script);
    }, []);

    const sendEmail = async () => {
        const body = `
        <center><img src='https://lh3.googleusercontent.com/drive-viewer/AITFw-zGITiLWLzZ_nm7hX82F4AhIYHzeXUQpLUimU48j7L9ABpX4rifT8KKoTKeWWm8M2tQNMqXdqv1g3cwsDEWsYc5dQNJvQ=s2560' alt="Logo" /></center>
        <h1 style="color: #f6552f;">Thank You For Registering for Early Access!</h1>
        <br />
        Dear ${name},
        <br />
        <br />
        We are thrilled to have you on board for our Early Access program! Your registration is successful and you are now a part of the exclusive group of forward-thinking individuals who will get to experience BlueQuail platform before anyone else.
        <br />
        <br />
        Your curiosity, enthusiasm, and insights are going to play a crucial role in shaping our product. We believe that your feedback will enable us to build a tool that truly adds value to businesses and helps them leverage the power of AI in the most effective way.
        <br />
        <br />
        In the coming weeks, you'll receive an invitation to access our platform, along with some guidelines on how to use it and provide feedback. We're excited to hear what you think and look forward to your invaluable inputs.
        <br />
        <br />
        In the meantime, stay tuned for updates, feature sneak peeks, and more on our journey to make the future of business smarter with AI.
        <br />
        <br />
        Again, thank you for being an early adopter.
        <br />
        <br />
        Best Regards,
        <br />
        <b>Marek,</b>
        <br />
        <b>BlueQuail Team</b>
        <br />
        <br />
        P.S. If you have any questions or need assistance, feel free to get in touch. We're here to help!
        <br />
        <br />
        <center><img src='https://lh3.googleusercontent.com/drive-viewer/AITFw-xxIxSUzsiO0wvi2gaDxl44CDeGr4t2JDaSG79s67Re-qaSU94z2_yfm4Wfq_baP2WHP6AwpNr60HgDqk3aSjMi65KX=s2560' alt="" /></center>
        <br />
        <center>
        <div style="background: #1b226f; padding: 40px;">
            <div style="padding: 20px;">
                <img src="https://lh3.googleusercontent.com/drive-viewer/AITFw-zuLI46Gmz-4YuUAKfiIQouPGSCw6Btey74Adk86muA9OMUFmEIK6iqNXyWyoqEjCInprmX9y-J0auWRL4m5WqSTRiK7w=s2560" alt="" />
            </div>
            <div style="padding: 20px;">
                <a href="https://www.linkedin.com/company/qedsoftware"><img src="https://lh3.googleusercontent.com/drive-viewer/AITFw-zXrtRQYiS05dPXzUsnmcLQOMC3VLdMNK1PrKzYybtB1muo2S477_ha3dBTPCNLdGzEgdZVxlBRbRoyL0BaEStqMUwXIQ=s2560" alt="" /></a>
                <a href="https://www.youtube.com/@qedsoftware"><img src="https://lh3.googleusercontent.com/drive-viewer/AITFw-x7sPB5ir4uH4HFhVpqemBa3j4rfjQurwIN9huaEw4UIZkjBFae5ILpOjXJhMIeFkJpRcIp47eTrGR0StKkev_gBOfkrA=s2560" alt="" /></a>
            </div>
            <b><a href="https://qed.pl">www.qed.pl</a></b>
        </div>
        </center>
        <br />
    `;

        const emailConfig = {
            SecureToken: 'fac7c6ed-f055-4459-b080-10d09aba102d',
            To: email,
            From: 'contact@bluequail.ai',
            Subject: 'Thank you',
            Body: body,
        };
        // @ts-ignore
        if (window.Email) {
            // @ts-ignore
            window.Email.send(emailConfig)
                .then((message: string) => console.log(message))
                .catch(() => navigate('/error'));
        }
        console.log('sent');
    };
    const handleForm = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        let errors = 0;
        if (handleNameValid()) errors++;
        if (handleEmailValid()) errors++;
        if (handleEmailCheckboxValid()) errors++;
        if (handlePolicyValid()) errors++;
        if (errors > 0) return;

        handleLoaded();
        await addDoc(collection(db, 'users'), {
            email: email,
            name: name,
            key: key,
            date: new Date(),
        });
        await sendEmail();
        props.onSend();
    };
    return (
        <form onSubmit={handleForm}>
            <div className='form-title'>Fill out the form and stay tuned:</div>
            <div className='textOnInput'>
                <label htmlFor='inputText' style={{backgroundColor:'var(--white)'}}>
                    FULL NAME<span style={{color: 'var(--error-red)'}}> *</span>
                </label>
                <input
                    className={
                        nameError ? 'form-control-invalid' : 'form-control'
                    }
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleNameValid}
                />
            </div>
            <div className='textOnInput'>
                <label htmlFor='inputText' style={{backgroundColor:'var(--white)'}}>
                    EMAIL ADDRESS<span style={{color: 'var(--error-red)'}}> *</span>
                </label>
                <input
                    className={
                        emailError ? 'form-control-invalid' : 'form-control'
                    }
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleEmailValid}
                />
            </div>
            <div className={'footer-checkboxes'}>
                <div style={{display: "flex"}}>
                    <div>
                        <input type='checkbox' id='policy' onChange={handlePolicyChange}/>
                    </div>
                    <div style={{marginLeft: 12}}>
                        <label htmlFor='policy'
                               className={!policyError ? 'checkbox-label' : 'checkbox-label form-error'}>
                            I consent to the processing of my personal data in accordance with
                            the{' '}
                            <Link to='/privacypolicy' className='pp-link'>
                                Privacy policy
                            </Link>
                            <span style={{color: 'var(--error-red)'}}> *</span>
                        </label>
                    </div>

                </div>
                <div style={{display: "flex"}}>
                    <div>
                        <input type='checkbox' id='email' onChange={handleEmailCheckboxChange}/>
                    </div>
                    <div style={{marginLeft: 12}}>
                        <label htmlFor='email'
                               className={!emailCheckboxError ? 'checkbox-label' : 'checkbox-label form-error'}>
                            I consent to receive email updates and marketing communications
                            <span style={{color: 'var(--error-red)'}}> *</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='form-button-container'>
                <div className='form-footer'>
                    <div className='textOnInput'>
                        <label className={'promo-1'} style={{backgroundColor:'var(--white)'}} htmlFor='inputText'>PROMO CODE</label>
                        <input
                            className='form-control promo'
                            type='text'
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                        />
                    </div>
                    <button className='get-button form-button' type='submit'>
                        EARLY SIGN UP
                    </button>
                </div>
            </div>
            <div
                className='g-recaptcha'
                data-sitekey='6LfGLiMnAAAAAAuZCGUuZEW1m9VF7D0yk7QHe9b5'
                data-size='invisible'
            ></div>
        </form>
    );
};

const CompletedForm = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
    }, []);
    return (
        <div className={`completed-form ${visible ? 'visible' : ''}`}>
            <img src='/images/mail.svg' alt='send email icon'/>
            <div className='register-completed'>
                Thank You for Registering for{' '}
                <span className='register-completed-ea'> Early Access!</span>
            </div>
        </div>
    );
};
