export interface ISector {
    name: string;
    keywords: Array<string>;
    qty: number;
}

export interface IUseCase {
    id: string;
    name: string;
    sectors: Array<string>;
    especially: Array<string>;
}

export const useCases:Array<IUseCase> = [
    {
        id: 'UC002',
        name: 'Sales Forecasting',
        sectors: [],
        especially: ['Retail and E-commerce', 'Real Estate and Construction'],
    },
    {
        id: 'UC003',
        name: 'Fraud Detection',
        especially: ['Finance and Banking'],
        sectors: [
            'Travel and Hospitality',
            'Retail and E-commerce',
            'Healthcare and Pharmaceuticals',
            'Finance and Banking',
            'Information Technology and Telecommunications',
            'Energy and Utilities',
            'Manufacturing and Industrial',
            'Education',
            'Media and Entertainment',
            'Transportation and Logistics',
            'Agriculture and Food Services',
            'Real Estate and Construction',
        ],
    },
    {
        id: 'UC033',
        name: 'Automated Document Classification',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC005',
        name: 'Churn Prediction',
        sectors: [],
        especially: ['Information Technology and Telecommunications', 'Media and Entertainment'],
    },
    {
        id: 'UC006',
        name: 'Quality Control',
        sectors: [],
        especially: ['Manufacturing and Industrial'],
    },
    {
        id: 'UC007',
        name: 'Customer Segmentation',
        sectors: [],
        especially: ['Retail and E-commerce', 'Media and Entertainment', 'Travel and Hospitality'],
    },
    {
        id: 'UC009',
        name: 'Sentiment Analysis',
        sectors: [],
        especially: ['Retail and E-commerce', 'Media and Entertainment', 'Travel and Hospitality'],
    },
    {
        id: 'UC010',
        name: 'Recommendation Systems',
        sectors: [],
        especially: ['Retail and E-commerce', 'Media and Entertainment', 'Travel and Hospitality'],
    },
    {
        id: 'UC011',
        name: 'Dynamic Pricing',
        sectors: [],
        especially: [
            'Retail and E-commerce',
            'Energy and Utilities',
            'Media and Entertainment',
            'Travel and Hospitality',
        ],
    },
    {
        id: 'UC012',
        name: 'Medical Research',
        sectors: ['Healthcare and Pharmaceuticals'],
        especially: [],
    },
    {
        id: 'UC013',
        name: 'Chat Analysis',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC014',
        name: 'Image Recognition',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC018',
        name: 'Social Media Analysis',
        sectors: [],
        especially: ['Retail and E-commerce', 'Media and Entertainment', 'Travel and Hospitality'],
    },
    {
        id: 'UC019',
        name: 'Autonomous Vehicles',
        sectors: [],
        especially: ['Transportation and Logistics'],
    },
    {
        id: 'UC020',
        name: 'Smart Agriculture',
        sectors: ['Agriculture and Food Services'],
        especially: ['Agriculture and Food Services'],
    },
    {
        id: 'UC021',
        name: 'Cybersecurity',
        sectors: [],
        especially: [
            'Information Technology and Telecommunications',
            'Finance and Banking',
            'Healthcare and Pharmaceuticals',
        ],
    },
    {
        id: 'UC024',
        name: 'Personalized Learning',
        sectors: [],
        especially: ['Education'],
    },
    {
        id: 'UC031',
        name: 'Medical Image Analysis',
        sectors: ['Healthcare and Pharmaceuticals'],
        especially: ['Healthcare and Pharmaceuticals'],
    },
    {
        id: 'UC034',
        name: 'Smart City Management',
        sectors: [],
        especially: [
            'Energy and Utilities',
            'Transportation and Logistics',
            'Real Estate and Construction',
            'Travel and Hospitality',
        ],
    },
    {
        id: 'UC036',
        name: 'Health Monitoring Wearables',
        sectors: [],
        especially: ['Healthcare and Pharmaceuticals'],
    },
    {
        id: 'UC037',
        name: 'Inventory Management',
        sectors: [],
        especially: ['Retail and E-commerce', 'Manufacturing and Industrial'],
    },
    {
        id: 'UC039',
        name: 'Personalized Medicine',
        sectors: ['Healthcare and Pharmaceuticals'],
        especially: [],
    },
    {
        id: 'UC041',
        name: 'Smart Traffic Management',
        sectors: [],
        especially: ['Transportation and Logistics'],
    },
    {
        id: 'UC042',
        name: 'Event Prediction',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC043',
        name: 'Recommendation Chatbots',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC044',
        name: 'Facial Recognition',
        sectors: [],
        especially: [
            'Information Technology and Telecommunications',
            'Healthcare and Pharmaceuticals',
            'Finance and Banking',
        ],
    },
    {
        id: 'UC045',
        name: 'Smart Waste Management',
        sectors: [],
        especially: ['Manufacturing and Industrial', 'Retail and E-commerce'],
    },
    {
        id: 'UC046',
        name: 'Text Summarization',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC047',
        name: 'Human Pose Estimation',
        sectors: [],
        especially: [],
    },
    {
        id: 'UC049',
        name: 'Cancer Diagnosis',
        sectors: ['Healthcare and Pharmaceuticals'],
        especially: ['Healthcare and Pharmaceuticals'],
    },
    {
        id: 'UC051',
        name: 'Emotion Recognition',
        sectors: [],
        especially: ['Media and Entertainment', 'Travel and Hospitality', 'Retail and E-commerce'],
    },
    {
        id: 'UC056',
        name: 'Facial Expression Synthesis',
        sectors: ['Media and Entertainment'],
        especially: ['Media and Entertainment'],
    },
    {
        id: 'UC060',
        name: 'Anomaly Detection in Manufacturing',
        sectors: ['Manufacturing and Industrial'],
        especially: ['Manufacturing and Industrial'],
    },
    {
        id: 'UC065',
        name: 'Intelligent Advertisement Placement',
        sectors: [],
        especially: ['Media and Entertainment', 'Retail and E-commerce'],
    },
    {
        id: 'UC069',
        name: 'Automated Melody Identification',
        sectors: ['Media and Entertainment', 'Retail and E-commerce'],
        especially: ['Media and Entertainment'],
    },
];
export const sectors: Array<ISector> = [
    {
        name: 'Travel and Hospitality',
        keywords: [
            'Travel',
            'Vacation',
            'Journey',
            'Adventure',
            'Trip',
            'Excursion',
            'Expedition',
            'Getaway',
            'Hotel',
            'Resort',
            'Accommodation',
            'Lodging',
            'Hospitality industry',
            'Guest services',
            'Customer service',
            'Tourist',
            'Tourism industry',
            'Sightseeing',
            'Tour package',
            'Attractions',
            'Destination',
            'Tour guide',
            'Airline',
            'Flight',
            'Airport',
            'Cruise',
            'Rail travel',
            'Car rental',
            'Transportation services',
            'Restaurants',
            'Cuisine',
            'Dining',
            'Food and beverage',
            'Culinary experience',
            'Gastronomy',
            'Online booking',
            'Reservation system',
            'Booking platform',
            'Availability',
            'Room reservation',
            'Ticket booking',
            'Conference facilities',
            'Event management',
            'Business travel',
            'Meeting spaces',
            'Convention center',
            'Entertainment',
            'Activities',
            'Recreation',
            'Leisure',
            'Outdoor adventure',
            'Tourist activities',
            'Itinerary',
            'Travel agency',
            'Travel planning',
            'Travel advisory',
            'Travel consultant',
            'Travel packages',
            'Customer satisfaction',
            'Guest experience',
            'Service quality',
            'Reviews and ratings',
            'Feedback',
            'Marketing strategies',
            'Promotional campaigns',
            'Branding',
            'Online marketing',
            'Social media presence',
            'Travel insurance',
            'Safety measures',
            'Security checks',
            'Emergency services',
            'Health protocols',
            'Sustainable travel',
            'Eco-friendly accommodations',
            'Responsible tourism',
            'Green initiatives',
            'Conservation efforts',
            'Cultural experiences',
            'Heritage sites',
            'Cultural festivals',
            'Historical attractions',
            'Cultural exchange',
            'Travel apps',
            'Online booking platforms',
            'Virtual tours',
            'Contactless services',
            'Tech-enabled travel solutions',
        ],
        qty: 0,
    },
    {
        name: 'Retail and E-commerce',
        keywords: [
            'Retail',
            'E-commerce',
            'Online shopping',
            'Shopping',
            'Shop',
            'Store',
            'Online store',
            'Retailer',
            'Marketplace',
            'Consumer',
            'Customer',
            'Product',
            'Inventory',
            'Catalog',
            'Shopping cart',
            'Checkout',
            'Payment',
            'Payment gateway',
            'Transaction',
            'Shipping',
            'Delivery',
            'Fulfillment',
            'Returns',
            'Exchange',
            'Shopping experience',
            'User interface',
            'Website',
            'Mobile app',
            'Digital commerce',
            'Brick-and-mortar',
            'Point of sale \\(POS\\)',
            'POS',
            'Point of sale',
            'E-tailer',
            'Sales',
            'Discounts',
            'Promotions',
            'Coupons',
            'Deals',
            'Loyalty program',
            'Customer reviews',
            'Ratings',
            'Product description',
            'Customer service',
            'Customer support',
            'E-commerce platform',
            'Conversion rate',
            'Shopping trends',
            'Personalization',
            'Virtual shopping',
            'Augmented reality \\(AR\\)',
            'Augmented reality',
            'AR',
            'Virtual reality \\(VR\\)',
            'Virtual reality',
            'VR',
            'Subscription service',
            'Dropshipping',
            'Affiliate marketing',
            'Cross-selling',
            'Upselling',
            'Supply chain',
            'Logistics',
            'Last-mile delivery',
            'Inventory management',
            'E-commerce analytics',
            'Omni-channel',
            'Multi-channel',
            'Social commerce',
            'Influencer marketing',
            'Online marketplace',
            'Product search',
            'Search engine optimization \\(SEO\\)',
            'Search engine optimization',
            'SEO',
            'Product recommendations',
            'Wishlist',
            'Abandoned cart',
            'Secure payment',
            'Cybersecurity',
            'Data privacy',
            'Digital marketing',
            'E-commerce strategy',
            'Mobile commerce',
            'Responsive design',
            'Responsive layout',
            'Product availability',
            'Click-through rate \\(CTR\\)',
            'Click-through rate',
            'CTR',
            'Conversion optimization',
            'User experience \\(UX\\)',
            'User experience',
            'UX',
            'User interface \\(UI\\)',
            'User interface',
            'UI',
            'Responsive customer service',
            'Cash on delivery \\(COD\\)',
            'Cash on delivery',
            'COD',
            'Electronic payment',
            'M-commerce',
            'Shopping app',
            'Online storefront',
            'Product display',
            'Shopping journey',
            'Shopping behavior',
        ],
        qty: 0,
    },
    {
        name: 'Healthcare and Pharmaceuticals',
        keywords: [
            'Healthcare',
            'Pharmaceuticals',
            'Medical',
            'Medicine',
            'Healthcare industry',
            'Medical services',
            'Healthcare provider',
            'Doctor',
            'Physician',
            'Nurse',
            'Hospital',
            'Clinic',
            'Medical facility',
            'Patient care',
            'Treatment',
            'Diagnosis',
            'Prescription',
            'Medication',
            'Pharmaceutical company',
            'Drug development',
            'Clinical trials',
            'Pharmaceuticals research',
            'Drug discovery',
            'Healthcare system',
            'Health services',
            'Medical equipment',
            'Medical technology',
            'Telemedicine',
            'Telehealth',
            'Electronic health records \\(EHR\\)',
            'Electronic health records',
            'EHR',
            'Medical imaging',
            'Radiology',
            'Surgery',
            'Medical procedures',
            'Wellness',
            'Preventive care',
            'Primary care',
            'Specialist',
            'Medical staff',
            'Healthcare professionals',
            'Medical research',
            'Biotechnology',
            'Health insurance',
            'Health policy',
            'Healthcare regulation',
            'Medical ethics',
            'Medical education',
            'Medical training',
            'Healthcare administration',
            'Medical billing',
            'Healthcare financing',
            'Patient safety',
            'Medical ethics',
            'Patient confidentiality',
            'Healthcare management',
            'Public health',
            'Epidemiology',
            'Healthcare communication',
            'Health awareness',
            'Medical treatment',
            'Disease prevention',
            'Healthcare facilities',
            'Healthcare infrastructure',
            'Healthcare accessibility',
            'Pharmacy',
            'Prescription drugs',
            'Over-the-counter \\(OTC\\) drugs',
            'Over-the-counter',
            'OTC',
            'Vaccines',
            'Pharmaceutical manufacturing',
            'Regulatory compliance',
            'Pharmacology',
            'Drug distribution',
            'Drug safety',
            'Healthcare technology',
            'Medical software',
            'Medical devices',
            'Health monitoring',
            'Remote patient monitoring',
            'Health data',
            'Health informatics',
            'Electronic medical records \\(EMR\\)',
            'Electronic medical records',
            'EMR',
            'Healthcare analytics',
            'Health IT',
            'Precision medicine',
            'Healthcare innovation',
            'Healthcare research',
            'Healthcare trends',
            'Medical breakthroughs',
            'Healthcare disparities',
            'Global health',
            'Mental health',
            'Telepsychiatry',
            'Healthcare services',
            'Healthcare consultation',
            'Medical consultation',
            'Healthcare network',
            'Healthcare professionals',
            'Patient care',
        ],
        qty: 0,
    },
    {
        name: 'Finance and Banking',
        keywords: [
            'Finance',
            'Banking',
            'Financial services',
            'Financial industry',
            'Banking sector',
            'Banking services',
            'Financial institution',
            'Bank',
            'Credit',
            'Savings',
            'Checking account',
            'Savings account',
            'Current account',
            'Account holder',
            'Deposits',
            'Withdrawals',
            'Interest rates',
            'Loans',
            'Mortgages',
            'Credit cards',
            'Debit cards',
            'Personal finance',
            'Wealth management',
            'Investment',
            'Investor',
            'Investment banking',
            'Retail banking',
            'Commercial banking',
            'Corporate banking',
            'Private banking',
            'Online banking',
            'Mobile banking',
            'Digital banking',
            'Fintech',
            'Financial technology',
            'Payment processing',
            'E-commerce payments',
            'Mobile payments',
            'Peer-to-peer \\(P2P\\) lending',
            'Peer-to-peer lending',
            'Peer-to-peer',
            'P2P',
            'Cryptocurrency',
            'Blockchain',
            'Digital currency',
            'Bitcoin',
            'Financial planning',
            'Retirement planning',
            'Estate planning',
            'Tax planning',
            'Financial advisory',
            'Financial consultant',
            'Financial analyst',
            'Portfolio management',
            'Asset management',
            'Wealth advisor',
            'Financial markets',
            'Stock market',
            'Stock exchange',
            'Bond market',
            'Commodities market',
            'Foreign exchange \\(Forex\\)',
            'Foreign exchange',
            'Forex',
            'Derivatives',
            'Trading',
            'Day trading',
            'Investment portfolio',
            'Risk management',
            'Credit risk',
            'Market risk',
            'Liquidity risk',
            'Regulatory compliance',
            'Financial regulation',
            'Anti-money laundering \\(AML\\)',
            'Anti-money laundering',
            'AML',
            'Know Your Customer \\(KYC\\)',
            'Know Your Customer',
            'KYC',
            'Financial reporting',
            'Accounting',
            'Auditing',
            'Financial statements',
            'Balance sheet',
            'Income statement',
            'Cash flow statement',
            'Corporate finance',
            'Capital markets',
            'Initial Public Offering \\(IPO\\)',
            'Initial Public Offering',
            'IPO',
            'Merger and acquisition \\(M&A\\)',
            'Merger and acquisition',
            'MGA',
            'Financial literacy',
            'Financial education',
            'Financial security',
            'Financial wellness',
            'Financial goals',
            'Economic indicators',
            'Interest rates',
            'Inflation',
            'Deflation',
            'Gross Domestic Product \\(GDP\\)',
            'Gross Domestic Product',
            'GOP',
            'Consumer Price Index \\(CPI\\)',
            'Consumer Price Index',
            'CPI',
            'Central bank',
            'Federal Reserve',
            'Monetary policy',
            'Banking regulations',
            'Financial stability',
            'Financial crisis',
            'Credit score',
            'Credit report',
            'Online trading',
            'Electronic trading',
            'Financial software',
            'Robo-advisors',
            'Automated investing',
            'Wealth accumulation',
            'Financial strategies',
        ],
        qty: 0,
    },
    {
        name: 'Information Technology and Telecommunications',
        keywords: [
            'Information Technology',
            'IT',
            'Telecommunications',
            'Tech industry',
            'Technology sector',
            'Digital',
            'Innovation',
            'IT services',
            'Telecommunication services',
            'Information systems',
            'Network',
            'Internet',
            'Connectivity',
            'Data',
            'Data management',
            'Data analytics',
            'Big data',
            'Cloud computing',
            'Cloud services',
            'Cybersecurity',
            'Network security',
            'Information security',
            'Cyber threats',
            'Data breaches',
            'Encryption',
            'Firewall',
            'Software development',
            'Programming',
            'Coding',
            'Software engineering',
            'Software solutions',
            'Web development',
            'Mobile app development',
            'User experience \\(UX\\)',
            'User experience',
            'UX',
            'User interface \\(UI\\)',
            'User interface',
            'UI',
            'Artificial Intelligence \\(AI\\)',
            'Artificial Intelligence',
            'AI',
            'Machine learning',
            'Deep learning',
            'Automation',
            'Robotics',
            'Internet of Things \\(IoT\\)',
            'Internet of Things',
            'IoT',
            'Smart devices',
            '5G',
            'Wireless technology',
            'Broadband',
            'Fiber optics',
            'Network infrastructure',
            'Network architecture',
            'Virtualization',
            'Data centers',
            'Server',
            'IT infrastructure',
            'Hardware',
            'Software',
            'Operating systems',
            'Tech support',
            'IT consulting',
            'IT solutions',
            'Digital transformation',
            'E-commerce',
            'Online presence',
            'Digital marketing',
            'Social media',
            'E-commerce platforms',
            'Digital platforms',
            'Tech startups',
            'Venture capital',
            'IT project management',
            'Agile methodology',
            'Scrum',
            'DevOps',
            'Software testing',
            'Quality assurance',
            'IT governance',
            'IT strategy',
            'IT management',
            'IT outsourcing',
            'Cloud migration',
            'Remote work',
            'Collaboration tools',
            'Video conferencing',
            'VoIP',
            'Unified communications',
            'IT certifications',
            'Information technology trends',
            'Emerging technologies',
            'IT innovation',
            'Mobile technology',
            'Wearable technology',
            'Information technology jobs',
            'IT careers',
            'IT education',
            'IT skills',
            'Coding languages',
            'Cybersecurity measures',
            'Network monitoring',
            'IT architecture',
            'IT consulting',
            'Software deployment',
            'Technology integration',
            'IT policies',
            'Technology standards',
            'IT regulations',
            'IT ethics',
        ],
        qty: 0,
    },
    {
        name: 'Energy and Utilities',
        keywords: [
            'Energy',
            'Utilities',
            'Energy sector',
            'Utility industry',
            'Power generation',
            'Renewable energy',
            'Fossil fuels',
            'Solar power',
            'Wind power',
            'Hydroelectric power',
            'Geothermal energy',
            'Biomass energy',
            'Nuclear power',
            'Energy sources',
            'Energy production',
            'Electricity',
            'Electric grid',
            'Energy distribution',
            'Energy transmission',
            'Energy consumption',
            'Energy efficiency',
            'Energy conservation',
            'Smart grid',
            'Energy infrastructure',
            'Power plants',
            'Power stations',
            'Power grid',
            'Energy storage',
            'Battery technology',
            'Energy management',
            'Energy markets',
            'Energy trading',
            'Energy pricing',
            'Energy supply',
            'Energy demand',
            'Energy policy',
            'Energy regulations',
            'Energy subsidies',
            'Energy transition',
            'Carbon emissions',
            'Carbon footprint',
            'Emission reduction',
            'Clean energy',
            'Green energy',
            'Sustainable energy',
            'Energy sustainability',
            'Energy independence',
            'Energy security',
            'Energy technology',
            'Energy innovation',
            'Energy research',
            'Energy exploration',
            'Oil and gas',
            'Natural gas',
            'Petroleum',
            'Crude oil',
            'Oil refining',
            'Fuel',
            'Energy conservation',
            'Energy audits',
            'Energy efficiency programs',
            'Energy efficiency standards',
            'Energy monitoring',
            'Energy analytics',
            'Energy consulting',
            'Energy contracts',
            'Utility services',
            'Water utility',
            'Natural gas utility',
            'Electric utility',
            'Utility infrastructure',
            'Utility rates',
            'Utility billing',
            'Utility management',
            'Energy savings',
            'Energy economics',
            'Energy projects',
            'Energy investments',
            'Energy financing',
            'Power distribution',
            'Grid reliability',
            'Energy crisis',
            'Energy policy',
            'Energy legislation',
            'Energy markets',
            'Energy subsidies',
            'Energy incentives',
            'Energy trading',
            'Energy consumption patterns',
            'Energy transformation',
            'Energy diversification',
            'Energy technology advancements',
            'Utility-scale projects',
            'Energy consulting',
            'Energy efficiency incentives',
            'Energy regulations',
            'Energy audits',
            'Energy monitoring systems',
            'Energy data analysis',
            'Smart meters',
            'Demand response',
            'Energy efficiency measures',
        ],
        qty: 0,
    },
    {
        name: 'Manufacturing and Industrial',
        keywords: [
            'Manufacturing',
            'Industrial sector',
            'Production',
            'Factory',
            'Plant',
            'Manufacturing industry',
            'Industrial processes',
            'Assembly line',
            'Automation',
            'Robotics',
            'Manufacturing technology',
            'Industrial machinery',
            'Manufacturing equipment',
            'Manufacturing processes',
            'Mass production',
            'Lean manufacturing',
            'Six Sigma',
            'Quality control',
            'Quality assurance',
            'Supply chain',
            'Logistics',
            'Inventory management',
            'Production efficiency',
            'Manufacturing operations',
            'Manufacturing management',
            'Manufacturing engineering',
            'Industrial design',
            'Product development',
            'Prototype',
            'Materials',
            'Raw materials',
            'Components',
            'Manufacturing standards',
            'Manufacturing safety',
            'Manufacturing regulations',
            'Manufacturing sustainability',
            'Waste management',
            'Environmental regulations',
            'Manufacturing workforce',
            'Skilled labor',
            'Industrial jobs',
            'Industrial automation',
            'CNC machining',
            '3D printing',
            'Additive manufacturing',
            'Industrial revolution',
            'Industrialization',
            'Industrialization trends',
            'Manufacturing trends',
            'Industrial innovation',
            'Industrial research',
            'Factory automation',
            'Industrial robotics',
            'Production planning',
            'Product lifecycle',
            'Batch production',
            'Continuous production',
            'Just-in-time manufacturing',
            'Production scheduling',
            'Industrial maintenance',
            'Equipment maintenance',
            'Process optimization',
            'Manufacturing software',
            'ERP systems',
            'MES systems',
            'Manufacturing analytics',
            'Industrial efficiency',
            'Industrial safety',
            'Industrial engineering',
            'Industrial economics',
            'Industry 4.0',
            'Smart manufacturing',
            'Digital transformation in manufacturing',
            'Industrial IoT',
            'Factory of the future',
            'Industrial growth',
            'Manufacturing capacity',
            'Industrial output',
            'Manufacturing competitiveness',
            'Industrial infrastructure',
            'Manufacturing supply chain',
            'Industrial markets',
            'Industrial products',
            'Industrial services',
            'Industrial raw materials',
            'Industrial sustainability',
            'Industrial waste reduction',
            'Industrial best practices',
            'Industrial regulations',
            'Industrial safety standards',
            'Manufacturing innovation',
            'Industrial development',
            'Industrial investments',
            'Industrial partnerships',
            'Industrial collaboration',
            'Manufacturing excellence',
            'Industrial training',
            'Manufacturing skills',
            'Industrial education',
            'Industry associations',
        ],
        qty: 0,
    },
    {
        name: 'Education',
        keywords: [
            'Education',
            'Learning',
            'School',
            'College',
            'University',
            'Educational institutions',
            'Academic',
            'Curriculum',
            'Classroom',
            'Teacher',
            'Professor',
            'Student',
            'Student learning',
            'Education system',
            'Education reform',
            'Educational technology',
            'E-learning',
            'Online learning',
            'Distance learning',
            'Blended learning',
            'Virtual classrooms',
            'Digital education',
            'Educational resources',
            'Textbooks',
            'Study materials',
            'Coursework',
            'Lectures',
            'Seminars',
            'Workshops',
            'Homework',
            'Assessment',
            'Exams',
            'Grading',
            'Report cards',
            'Education policy',
            'Education standards',
            'Accreditation',
            'Certification',
            'Diploma',
            'Degree',
            'Academic programs',
            'Majors',
            'Minors',
            'Academic research',
            'Educational development',
            'Educational psychology',
            'Pedagogy',
            'Andragogy',
            'Instructional design',
            'Learning objectives',
            'Learning outcomes',
            'Educational assessment',
            'Educational evaluation',
            'Learning styles',
            'Education funding',
            'Scholarships',
            'Grants',
            'Financial aid',
            'Student loans',
            'Education administration',
            'School management',
            'Campus',
            'Facilities',
            'Educational leadership',
            'Education curriculum',
            'Core subjects',
            'Elective courses',
            'Extracurricular activities',
            'Student organizations',
            'School culture',
            'Education technology',
            'EdTech',
            'Learning management systems \\(LMS\\)',
            'Learning management systems',
            'LMS',
            'Educational apps',
            'Digital resources',
            'Educational software',
            'Classroom technology',
            'Education trends',
            'Educational innovation',
            'Lifelong learning',
            'Adult education',
            'Continuing education',
            'Vocational education',
            'Professional development',
            'Teacher training',
            'Educational research',
            'Education statistics',
            'Education data',
            'Education analytics',
            'Education advocacy',
            'Education associations',
            'Education conferences',
            'Education advocacy',
            'Education diversity',
            'Inclusion',
            'Special education',
            'Education for students with disabilities',
            'Education for gifted students',
            'Education equity',
            'Education outreach',
            'Education partnerships',
            'Education collaboration',
            'Education outreach',
            'Education sustainability',
            'Global education',
            'International education',
            'Cross-cultural learning',
            'Education challenges',
            'Education solutions',
            'Education empowerment',
            'Education quality',
            'Education reform',
            'Education transformation',
            'Education goals',
            'Education philosophy',
            'Education ethics',
            'Education careers',
            'Education jobs',
        ],
        qty: 0,
    },
    {
        name: 'Media and Entertainment',
        keywords: [
            'Media',
            'Entertainment',
            'Media industry',
            'Entertainment industry',
            'Broadcasting',
            'Journalism',
            'Mass media',
            'Digital media',
            'Print media',
            'Television',
            'Radio',
            'Film',
            'Cinema',
            'Streaming',
            'Online content',
            'News',
            'Articles',
            'Reporting',
            'Media coverage',
            'Media channels',
            'Media outlets',
            'Media platforms',
            'Media consumption',
            'Media content',
            'Media production',
            'Media distribution',
            'Media trends',
            'Media innovation',
            'Media technology',
            'Media convergence',
            'Media ethics',
            'Media regulations',
            'Media censorship',
            'Media influence',
            'Media impact',
            'Media diversity',
            'Media representation',
            'Media literacy',
            'Media studies',
            'Media theory',
            'Social media',
            'Social networking',
            'Social platforms',
            'User-generated content',
            'Viral content',
            'Influencers',
            'Content creators',
            'Digital storytelling',
            'Podcasts',
            'Streaming platforms',
            'Video content',
            'Audio content',
            'Live broadcasts',
            'Reality TV',
            'Scriptwriting',
            'Screenwriting',
            'Film production',
            'Television production',
            'Content production',
            'Creative industries',
            'Creative content',
            'Arts and culture',
            'Music',
            'Concerts',
            'Performing arts',
            'Live performances',
            'Theatre',
            'Acting',
            'Celebrity',
            'Celebrities',
            'Entertainment events',
            'Entertainment news',
            'Celebrity gossip',
            'Pop culture',
            'Entertainment trends',
            'Entertainment marketing',
            'Entertainment branding',
            'Entertainment experiences',
            'Entertainment consumption',
            'Entertainment platforms',
            'Entertainment technology',
            'Gaming',
            'Video games',
            'Esports',
            'Virtual reality \\(VR\\)',
            'Virtual reality',
            'VR',
            'Augmented reality \\(AR\\)',
            'Augmented reality',
            'AR',
            'Interactive media',
            'Multimedia',
            'Animation',
            'Visual effects',
            'Special effects',
            'Content distribution',
            'Content licensing',
            'Intellectual property',
            'Copyright',
            'Licensing agreements',
            'Royalties',
            'Media advertising',
            'Marketing campaigns',
            'Promotional strategies',
            'Public relations',
            'Media partnerships',
            'Media sponsorships',
            'Media events',
            'Media exposure',
            'Media audiences',
            'Ratings',
            'Audiences',
            'Viewership',
            'Readership',
            'Listenership',
            'Media engagement',
            'Media monetization',
            'Media revenue',
            'Media business models',
            'Media startups',
            'Media acquisitions',
            'Media mergers',
            'Media consolidation',
            'Media competition',
            'Media impact',
            'Media analysis',
            'Media research',
            'Media critique',
            'Media critique',
            'Media consumption patterns',
            'Media behavior',
            'Media psychology',
        ],
        qty: 0,
    },
    {
        name: 'Transportation and Logistics',
        keywords: [
            'Transportation',
            'Logistics',
            'Supply chain',
            'Shipping',
            'Freight',
            'Cargo',
            'Transportation industry',
            'Logistics industry',
            'Transport services',
            'Logistics services',
            'Freight forwarding',
            'Warehousing',
            'Distribution',
            'Transportation management',
            'Fleet management',
            'Freight transportation',
            'Freight logistics',
            'Freight carriers',
            'Shipping companies',
            'Trucking',
            'Rail transportation',
            'Air freight',
            'Ocean freight',
            'Container shipping',
            'Freight rates',
            'Cargo handling',
            'Inventory management',
            'Inventory control',
            'Last-mile delivery',
            'First-mile delivery',
            'Reverse logistics',
            'Cross-border logistics',
            'International shipping',
            'Customs clearance',
            'Import',
            'Export',
            'Freight brokerage',
            'Transportation technology',
            'Logistics technology',
            'Route optimization',
            'Transportation networks',
            'Delivery services',
            'Carrier partnerships',
            'Freight tracking',
            'Transport infrastructure',
            'Ports',
            'Harbors',
            'Terminals',
            'Airports',
            'Rail terminals',
            'Intermodal transportation',
            'Multimodal transportation',
            'Supply chain management',
            'Demand forecasting',
            'Just-in-time \\(JIT\\) logistics',
            'Just-in-time logistic',
            'JIT',
            'Inventory replenishment',
            'Order fulfillment',
            'E-commerce logistics',
            'Third-party logistics \\(3PL\\)',
            'Third-party logistics',
            '3PL',
            'Fourth-party logistics \\(4PL\\)',
            'Fourth-party logistics',
            '4PL',
            'Freight management software',
            'Transportation analytics',
            'Logistics automation',
            'Digital supply chain',
            'Supply chain visibility',
            'Warehousing solutions',
            'Inventory tracking',
            'Inventory optimization',
            'Transportation efficiency',
            'Logistics optimization',
            'Freight security',
            'Transportation regulations',
            'Environmental regulations',
            'Sustainability in logistics',
            'Green transportation',
            'Transportation costs',
            'Freight costs',
            'Shipping rates',
            'Carrier negotiations',
            'Freight capacity',
            'Transportation capacity',
            'Transportation trends',
            'Logistics trends',
            'Urban logistics',
            'Rural logistics',
            'Global logistics',
            'Local transportation',
            'Regional transportation',
            'Transportation safety',
            'Road safety',
            'Fleet safety',
            'Regulatory compliance',
            'Transport economics',
            'Logistics economics',
            'Transport planning',
            'Logistics planning',
            'Transportation challenges',
            'Logistics challenges',
            'Transportation partnerships',
            'Intermodal logistics',
            'Distribution centers',
            'Warehouse management',
            'Distribution networks',
            'Delivery routes',
            'Express shipping',
            'Same-day delivery',
            'Next-day delivery',
            'Cold chain logistics',
            'Perishable goods transportation',
            'Bulk transportation',
            'Supply chain disruptions',
            'Crisis management in logistics',
        ],
        qty: 0,
    },
    {
        name: 'Agriculture and Food Services',
        keywords: [
            'Agriculture',
            'Farming',
            'Agriculture industry',
            'Food production',
            'Food industry',
            'Agriculture and food',
            'Agribusiness',
            'Crop cultivation',
            'Livestock farming',
            'Agricultural technology',
            'Agricultural equipment',
            'Agricultural practices',
            'Sustainable agriculture',
            'Organic farming',
            'Crop yield',
            'Soil health',
            'Crop rotation',
            'Irrigation',
            'Pest management',
            'Fertilizers',
            'Pesticides',
            'Crop protection',
            'Agrochemicals',
            'Agricultural research',
            'Agricultural innovation',
            'Agricultural development',
            'Crop diversity',
            'Genetic engineering',
            'Genetically modified organisms \\(GMOs\\)',
            'Genetically modified organisms',
            'GMO',
            'Biotechnology in agriculture',
            'Agroforestry',
            'Hydroponics',
            'Aquaponics',
            'Greenhouse farming',
            'Vertical farming',
            'Precision agriculture',
            'Farm management',
            'Agricultural economics',
            'Agricultural markets',
            'Agricultural trade',
            'Food supply chain',
            'Food safety',
            'Food regulations',
            'Food quality',
            'Food traceability',
            'Food labeling',
            'Food processing',
            'Food packaging',
            'Food distribution',
            'Food waste',
            'Food sustainability',
            'Food preservation',
            'Foodborne illnesses',
            'Foodborne pathogens',
            'Foodborne outbreaks',
            'Farm-to-table',
            'Local food',
            'Organic food',
            'Sustainable food',
            'Food security',
            'Hunger relief',
            'Food access',
            'Food deserts',
            'Food policy',
            'Agricultural policy',
            'Agricultural subsidies',
            'Food pricing',
            'Food trends',
            'Food preferences',
            'Culinary arts',
            'Restaurant industry',
            'Food service',
            'Catering',
            'Menu planning',
            'Culinary education',
            'Food presentation',
            'Food flavors',
            'Food innovation',
            'Culinary innovation',
            'Food technology',
            'Food startups',
            'Culinary experiences',
            'Farm markets',
            "Farmers' markets",
            'Agricultural trade shows',
            'Food festivals',
            'Culinary competitions',
            'Food culture',
            'Food traditions',
            'Nutrition',
            'Dietary choices',
            'Healthy eating',
            'Food education',
            'Food awareness',
            'Food labels',
            'Food labeling regulations',
            'Food certifications',
            'Food packaging innovation',
            'Food branding',
            'Food advertising',
            'Farm labor',
            'Agricultural workforce',
            'Food industry jobs',
            'Agricultural education',
            'Agri-tourism',
            'Agronomy',
            'Horticulture',
            'Viticulture',
            'Animal husbandry',
            'Dairy farming',
            'Poultry farming',
            'Livestock management',
            'Food trends',
            'Sustainable practices in agriculture',
            'Local sourcing',
            'Crop genetics',
            'Agricultural machinery',
        ],
        qty: 0,
    },
    {
        name: 'Real Estate and Construction.',
        keywords: [
            'Real Estate',
            'Construction',
            'Real estate industry',
            'Construction industry',
            'Property',
            'Residential real estate',
            'Commercial real estate',
            'Real estate development',
            'Real estate investment',
            'Real estate market',
            'Property management',
            'Real estate transactions',
            'Real estate brokerage',
            'Real estate agents',
            'Real estate listings',
            'Housing market',
            'Rental properties',
            'Homeownership',
            'Property values',
            'Real estate appraisal',
            'Real estate financing',
            'Real estate loans',
            'Mortgage',
            'Real estate investment trust \\(REIT\\)',
            'Real estate investment trust',
            'REIT',
            'Real estate portfolio',
            'Real estate investment strategies',
            'Real estate trends',
            'Real estate regulations',
            'Zoning',
            'Land use',
            'Real estate contracts',
            'Lease agreements',
            'Property rights',
            'Property taxes',
            'Land development',
            'Land acquisition',
            'Site preparation',
            'Architecture',
            'Architectural design',
            'Building design',
            'Construction planning',
            'Building permits',
            'Construction permits',
            'Construction materials',
            'Building codes',
            'Construction standards',
            'Residential construction',
            'Commercial construction',
            'Construction management',
            'Construction projects',
            'Construction technology',
            'Sustainable construction',
            'Green building',
            'LEED certification',
            'Construction financing',
            'Construction loans',
            'Real estate development financing',
            'Project management',
            'Construction timeline',
            'Construction cost estimation',
            'Construction scheduling',
            'Construction contracts',
            'Construction safety',
            'Construction workforce',
            'Construction labor',
            'Construction equipment',
            'Construction machinery',
            'Property maintenance',
            'Property renovations',
            'Property improvements',
            'Real estate marketing',
            'Real estate advertising',
            'Real estate branding',
            'Property listings',
            'Real estate photography',
            'Real estate staging',
            'Real estate websites',
            'Real estate platforms',
            'Real estate analytics',
            'Real estate data',
            'Real estate trends',
            'Property valuation',
            'Real estate consulting',
            'Real estate investment consulting',
            'Real estate law',
            'Real estate transactions',
            'Real estate negotiations',
            'Property inspections',
            'Real estate due diligence',
            'Real estate auctions',
            'Property development',
            'Real estate investment opportunities',
            'Real estate returns',
            'Real estate market analysis',
            'Real estate risk assessment',
            'Real estate diversification',
            'Real estate investment returns',
            'Construction companies',
            'Property management companies',
            'Real estate partnerships',
            'Real estate syndication',
            'Real estate networking',
            'Property investment strategies',
            'Real estate conferences',
            'Property development',
            'Real estate consulting',
            'Real estate negotiations',
        ],
        qty: 0,
    },
];
