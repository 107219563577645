import React, { useLayoutEffect, useState} from "react";
import './DescriptionPage.scss';
// @ts-ignore
import descriptionImage from '../../images/description.svg';
// import OrganizationMenu from "../organization/menu/leftMenu/OrganizationMenu";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import {useNavigate, useParams} from 'react-router-dom';
import {getUseCase, useAppDispatch} from "../../helpers";
import {FormPage} from "../FormPage";
// @ts-ignore
import phoneIcon from "../../images/phone-ringing 1.svg";
import Loader from "../loader/Loader";
import {useSelector} from "react-redux";
// @ts-ignore
import comingSoonIcon from '../../images/coming-soon.svg';
import {loaderR, setLoader} from "../../store/reducers/loaderReducer";

export default function DescriptionPage() {
    const params = useParams();
    const [currentItem, setCurrentItemn] = useState<any | null>(null); 
    const {usecaseId} = params;
    const loader = useSelector(loaderR)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useLayoutEffect(() => {



        if(usecaseId) {
            dispatch(setLoader(true))
            getUseCase(usecaseId).then(el => {
                setCurrentItemn(el)
                dispatch(setLoader(false))
            }).finally(() => dispatch(setLoader(false)))
        }
      }, []);
      
    return (
        <div>
            <Loader open={loader}/>

            <div className="description-page">
                {currentItem && (
                <div className="description-section">
                    <Breadcrumbs/>
                    <div>
                        <div className="desc-title">{currentItem?.name}</div>
                    </div>
                    <img className="desc-image" width={1340} height={100} style={{borderRadius:5}} src={`images/useCases/W1024/${usecaseId}_1024.png`} alt="" />
                    <div>
                        <div className="desc-container-title">DESCRIPTION</div>
                        <div className="two-columns-container">
                            {currentItem.content.description.length === 2 ? (
                                <>
                                    <div className="column">
                                        <div dangerouslySetInnerHTML={{__html: currentItem?.content.description[0]}} />
                                    </div>
                                    <div className="column">
                                        <div dangerouslySetInnerHTML={{__html: currentItem?.content.description[1]}} />
                                    </div>
                                </>
                            ) : (
                            <>
                                <div className="column">
                                    <div dangerouslySetInnerHTML={{__html: currentItem?.content.description[0]}} />
                                    <br />
                                    <br />
                                    <div dangerouslySetInnerHTML={{__html: currentItem?.content.description[1]}} />
                                </div>
                                <div className="column">
                                    <div dangerouslySetInnerHTML={{__html: currentItem?.content.description[2]}} />
                                    <br />
                                    <br />
                                    <div dangerouslySetInnerHTML={{__html: currentItem?.content.description[3]}} />
                                </div>
                            </>
                            )}
                        </div>
                        <div className="desc-container-title">TECHNICAL ASPECTS</div>
                        <div className="desc-container-text">{currentItem?.content.technicalAspectsDesc}</div>

                        <div className="two-columns-container">
                            <div className="column">
                                <div className="desc-list">
                                    {currentItem?.content.technicalAspects && (
                                        currentItem.content.technicalAspects.slice(0, Math.ceil(currentItem.content.technicalAspects.length / 2)).map((el: any, index: number) => (
                                            <div key={index} className="desc-list-element">
                                                <div className="numberCircle">{index + 1}</div>
                                                <div>{el}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                            <div className="column">
                                <div className="desc-list">
                                    {currentItem?.content.technicalAspects && (
                                        currentItem.content.technicalAspects.slice(Math.ceil(currentItem.content.technicalAspects.length / 2)).map((el: any, index: number) => (
                                            <div key={index} className="desc-list-element">
                                                <div className="numberCircle">{index + 1 + Math.ceil(currentItem.content.technicalAspects.length / 2)}</div>
                                                <div>{el}</div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
            <div className="real-life-title">Preview real-life example of {currentItem?.name} projects:</div>
            <img src={comingSoonIcon} alt="" />
            <div className="coming-soon">Coming soon</div>
            {!loader&&<div style={{background:'linear-gradient(180deg, #E6E8FF 0%, rgba(229, 229, 229, 0) 100%)'}}>
                <div style={{marginTop:1, height:1}}/>
                <div>

                    <FormPage/>
                </div>
            </div>}

        </div>
    )
}
