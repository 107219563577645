import { configureStore } from '@reduxjs/toolkit';
import useCasesSlice from './reducers/useCasesReducer';
import loaderSlice from "./reducers/loaderReducer";

const rootReducer = {
    useCases: useCasesSlice,
    loader:loaderSlice
};
export const blueQuailStore = configureStore({
    reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof blueQuailStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof blueQuailStore.dispatch
