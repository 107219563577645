import React, {useEffect, useState} from "react";
import './breadcrumbs.scss'
// @ts-ignore
import startIcon from '../../images/geoPoint.svg'
// @ts-ignore
import arrowRightIcon from '../../images/arrowRight.svg'
import {useLocation, useNavigate} from "react-router-dom";
import {getUseCase, pathDict} from "../../helpers";

export default function Breadcrumbs() {
    const location = useLocation()
    const path = location.pathname
    const [names, setNames] = useState<Array<string>>([])
    const [useCaseName, setUseCaseName] = useState('');
    const [useCaseId, setUseCaseId] = useState('');
    const navigate = useNavigate()
    useEffect(() => {
        const pathToSlice = path.slice(1).split('/');
        const pattern = /^\/use-cases\//;
        if(pattern.test(path)) {
            const tempUseCaseId = pathToSlice[pathToSlice.length - 1]
            getUseCase(tempUseCaseId).then(el => {
                setUseCaseName(el.name);
                setUseCaseId(el.id);
            });
            pathToSlice[pathToSlice.length - 1] = 'use-case-item';

        }
        setNames(pathToSlice)
    }, [path]);

    return (
        <div>
            <div className={'breadcrumbs'}>
                <img src={startIcon} alt={'start icon'}/>
                <div data-testid={'home-page'} style={{marginLeft: 10, cursor:"pointer"}} onClick={()=>navigate('/')}>Home page</div>
                <img src={arrowRightIcon} alt={'arrow'} style={{margin:'0 10px'}}/>
                {names.map((d:string, i) => {
                    return (
                        <div key={i} style={{display:"flex"}}>
                            <div data-testid={'breadcrumb'} style={{cursor:"pointer", color:i === names.length-1?'var(--blue)':''}} onClick={()=>navigate(pathDict[d].path !== 'use-case-path' ? pathDict[d].path : `/use-cases/${useCaseId}`)}>
                                {pathDict[d]?.name !== 'use-case-name' ? pathDict[d]?.name : useCaseName}
                            </div>
                            {i < names.length-1&&<img src={arrowRightIcon} alt={'arrow'} style={{margin:'0 10px'}}/>}
                        </div>

                    )
                })}
            </div>
        </div>
    )
}
