import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IUseCase } from '../../components/naiveModel/data';

const initialState: { fittedUseCasesR: Array<IUseCase>, promptR?:string } = {
    fittedUseCasesR: [],
    promptR:''
};

export const useCasesSlice = createSlice({
    name:'useCases',
    initialState,
    reducers: {
        setFittedUseCases: (state, data:PayloadAction<Array<IUseCase>>) =>{
            state.fittedUseCasesR = data.payload
        },
        setPrompt:(state, data:PayloadAction<string>) => {
            state.promptR=data.payload
        }
    }
})

export const {setFittedUseCases, setPrompt} = useCasesSlice.actions
export const fittedUseCasesR = (state:RootState) => state.useCases.fittedUseCasesR
export const promptR = (state:RootState) => state.useCases.promptR

export default useCasesSlice.reducer
