import {useEffect, useRef} from "react";

interface IProps {
    label?:string
    onClick?:Function
    active?:boolean
    areaCode?: any
}
export default function SelectItem(props:IProps) {
    const item = useRef<HTMLInputElement>(null)

    const onClickFn = () => {
        if (props.onClick) props.onClick(props.label)
    }
    useEffect(() => {
        if (props.active) {
            item.current?.classList.add('active')
        } else {
            item.current?.classList.remove('active')
        }
    }, [props.active]);
    return(
        <div data-testid={'select-item'} ref={item} className={'select-item'} style={{position:"relative", padding:props.label?'':0}} onClick={onClickFn}>
            {props.areaCode&&<div>
                <div className={'area-code'} style={{backgroundPosition: `${-props.areaCode?.pos[0]}px ${-props.areaCode?.pos[1]}px`, top:1, left:5}}/>
            </div>}
            <div style={{marginLeft:props.areaCode?20:0}}>{props.label}</div>
            {props.areaCode&&<div style={{marginLeft:10, color:'gray', width:56}}>{props.areaCode.dialCode}</div>}
        </div>
    )
}
