import { IUseCase } from '../naiveModel/data';
import { getUseCase } from '../../helpers';

export interface IUseCaseExt extends IUseCase {
    content: {
        technicalAspectsDesc: string;
        description: Array<string>;
        technicalAspects: Array<string>;
    };
    hover?:boolean
}

export const getAllUseCases = async (fittedUseCases:Array<IUseCase>) => {
    const tempItems: Array<IUseCaseExt> = [];
    for (let useCase of fittedUseCases) {
        await getUseCase(useCase.id).then((data) => {
            if (data) {
                tempItems.push(data);
            }
        });
    }
    return tempItems;
};
