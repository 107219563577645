import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div
      className='privacy-page'
      style={{ backgroundImage: 'url(images/4276.svg)' }}
    >
      <div className='privacy-policy'>
        <div className='title'>
          <div className='title-text-two'>Privacy policy</div>
        </div>
        <button className='get-button pp-button' onClick={() => navigate('/')}>
          BACK TO HOME PAGE
        </button>
        <div>
          <p className='pp-main'>
            This privacy policy (hereinafter the “Privacy Policy”) specifies the
            principles of processing (including collecting and use) of personal
            data by QED Software Sp. z o. o. with a registered office in Warsaw
            (00-052), ul. Mazowiecka 11/49, entered into the register of
            entrepreneurs of the National Court Register, maintained by the
            District Court for the capital city of Warsaw, XII Commercial
            Division, KRS: 0000174197, with tax identification number NIP:
            5262370692 (hereinafter “we,” “us”) along with the information on
            the rights of private individuals. Personal data is processed by us
            for the purposes and in the scope indicated in this Privacy Policy.
            Definitions:Whenever this Privacy Policy mentions the following
            capitalised terms, they shall be construed as follows:
          </p>
          <div className='pp-details'>
            <ul>
              <li>
                Data Controller / ADO – the data controller with respect to
                personal data shall be QED Software Sp. z o. o. with a
                registered office in Warsaw (00-052), ul. Mazowiecka 11/49,
                entered into the register of entrepreneurs of the National Court
                Register, maintained by the District Court for the capital city
                of Warsaw, XII Commercial Division, KRS: 0000174197, with tax
                identification number (NIP): 5262370692 and statistical number
                (REGON): 014909604 and with the following e-mail address:
                contact@qed.pl
              </li>
              <li>
                Customer – any person the Controller renders software services
                to on the principles and in the scope arising from the agreement
                concluded between the Parties;
              </li>
              <li>
                Contractor/Processor – any person cooperating with the
                Controller, processing personal data in relation to which the
                Controller is deemed an ADO, on the principles and in the scope
                arising from the agreement concluded between the parties;
              </li>
              <li>
                GDPR – Regulation (EU) 2016/679 of the European Parliament and
                of the Council (EU) of 27 April 2016 on the protection of
                natural persons with regard to the processing of personal data
                and on the free movement of such data, and repealing Directive
                95/46/EC (“General Data Protection Regulation”);
              </li>
              <li>
                Website – the following Internet domain:{' '}
                <a href='https://bluequail.ai/' className='pp-orange'>
                  www.bluequail.ai 
                </a>
              </li>
              <li>
                User – any person using the Website and/or contacting the
                Controller by telephone, e-mail or post.
              </li>
            </ul>
          </div>
          <p className='pp-main'>
            Purpose and grounds for the processing of personal data: Personal
            data is processed by us for the purposes and in the scope indicated
            in this Privacy Policy – in relation to:
          </p>
          <div className='pp-details'>
            <ul>
              <li>
                Users – for the purpose of handling the matters constituting the
                subject matter of correspondence. User Information is processed
                pursuant to Article 6 section 1 (f) of the GDPR (i.e. pursuit of
                the Controller’s legitimate interest in the form of
                communication with the users of the Website);
              </li>
              <li>
                Customers – for the purpose of rendering software services,
                subject to the principles and in the scope according to
                individual arrangements, pursuant to Article 6 section 1 (b) of
                the GDPR (i.e. it is necessary for the performance of a contract
                to which the data subject is party);
              </li>
              <li>
                Contractors – for the purpose of completion of the contract
                between the Parties (including the data processing agreement,
                pursuant to Article 28 of the GDPR), pursuant to Article 6
                section 1 (f) of the GDPR (i.e. the necessity of processing
                arises from the Controller’s legitimate interest);
              </li>
            </ul>
          </div>
          <p className='pp-details'>
            and in the cases in which the provisions of law authorise the
            Controller to process personal data. If we process data for purposes
            other than those arising from the Privacy Policy, the data subject
            shall each time be informed thereof.
          </p>
          <p className='pp-details'>
            Categories of personal data processing:Personal data is processed by
            us for in the scope indicated in this Privacy Policy – in relation
            to:
          </p>
          <div className='pp-details'>
            <ul>
              <li>
                Users – including: first and last name, e-mail address and/or
                telephone number;
              </li>
              <li>
                Customers – in the scope arising from individual arrangements,
                necessary to perform software services and individually
                specified by the Customer;
              </li>
              <li>
                Contractors– in the scope necessary for the performance of the
                agreement between the Parties, i.e. first name and surname,
                official position, official telephone number and e-mail address,
                as well the details necessary for the issue of a VAT invoice;
              </li>
            </ul>
          </div>
          <p className='pp-main'>
            If we process data in a scope other than that arising from the
            Privacy Policy, the data subject shall each time be informed
            thereof.Period of personal data storage:
          </p>
          <p className='pp-details'>
            Personal data is processed for the period of User’s use of the
            Website or another period which is necessary for the completion of
            the purposes for which such data was collected. Additionally,
            personal data will be stored for the period of resolution of any
            actual claims or the period in which any potential claims could
            arise and have not been become subject to limitation. The period of
            storage of Candidates’ personal data depends on the nature of the
            consent given by the given Candidate. In the event of consent to the
            processing of personal data necessary to perform the aforementioned
            recruitment process – until the conclusion of such process; in the
            case of consent to the processing of data also for the purpose of
            recruitment in the future – we store the Candidates’ data also
            following the conclusion of the particular recruitment process
            (however not later than for a period of one year).
          </p>
          <p className='pp-main'>Recipients of data:</p>
          <p className='pp-details'>
            The personal data of Users and Customers may be provided to
            authorised institutions specified in the relevant provisions of law
            as well as processing entities which render services for the benefit
            of the Controller. The personal data collected by the Controller may
            be directly accessed only by the Controller’s authorised employees
            or contractors.
            <br />
            Rights of data subjects:
          </p>
          <p className='pp-main'>A data subject is entitled to:</p>
          <div className='pp-details'>
            <ol>
              <li>
                access the contents of their personal data and receive copies
                thereof;
              </li>
              <li>rectify their personal data;</li>
              <li>
                demand the erasure of their personal data; however, if the given
                data subject has expressed consent to the processing of their
                personal data, a demand for the erasure of their personal data
                shall have the same effect as withdrawal of the consent;
              </li>
              <li>
                demand a restriction of processing of their personal data;
              </li>
              <li>transfer their personal data;</li>
              <li>object the processing of their personal data;</li>
              <li>
                withdraw their consent (in the event that the Controller
                processes data based on such consent) at any time and without
                prejudice to the lawfulness of processing carried out pursuant
                to the consent prior to its withdrawal;
              </li>
              <li>
                lodge a complaint with the supervisory authority regarding the
                violation of their right of data protection by the Controller or
                other rights granted pursuant to the provisions of the GDPR. The
                supervisory authority in Poland shall be the President of the
                Personal Data Protection Office.
              </li>
            </ol>
          </div>
          <p className='pp-details'>
            In order to exercise the aforementioned rights, a data subject
            should send us an e-mail at contact@bluequail.ai indicating: a)
            which right they wish to exercise, b) in what scope and c) in what
            manner. Please provide your contact details which will enable us to
            contact you quickly during the handling of your request – in
            particular where it may be necessary to confirm the identity of the
            applicant or the exact right the applicant wishes to exercise as
            well as the scope in which they wish to exercise it (telephone
            contact is preferred, although it is not obligatory).
          </p>
        </div>
      </div>
    </div>
  );
}
