import './Loader.scss'
import {useEffect} from "react";
import {setOverflow} from "../../helpers";

interface IProps {
    open: boolean
}

export default function Loader(props: IProps) {

    useEffect(() => {
        if (props.open) {
            setOverflow('hidden')
        } else {
            setOverflow('auto')
        }
    }, [props.open]);

    return (
        <>
        {props.open && <div style={{zIndex:1000, position:"fixed", left:0, top:0,display:"flex", justifyContent:"center", alignItems:"center", width:'100vw', height:'100vh', background:"transparent"}}>
            <div className={'loader'}>

            </div>
            <div className="lds-ring" style={{zIndex:1001}}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

        </div>

}
</>
)
}
