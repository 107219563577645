import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from './store/store';
import { collection, doc, getDoc, getDocs, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


export const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
};
export const validatePassword = (inputValue: string) => {
    const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*|<>`()\-_=+,.:;"'?/\\{}])[A-Za-z\d!@#|$%^&*<>`()\-_=+,.:;"'?/\\{}]{8,}$/;
    return regex.test(inputValue);
};

export const validateLength = (text: string, length?: number) => {
    let checkLength = length || 0;
    return text.length > checkLength;
};

export const validatePhoneNumber = (number: string) => {
    number = number.replace(/\D/g, '');
    return number;
};
export const validateAreaNumber = (number: string) => {
    number = number.replace(/\D/g, '');
    const reg = /\d{0,4}/;
    const checkNumber = number.match(reg);
    return '+ ' + checkNumber;
};

export const pathDict: { [key: string]: { name: string; path: string } } = {
    organization: { name: 'Organization', path: '/organization' },
    info: { name: 'Basic info', path: '/organization/info' },
    projects: { name: 'Projects', path: '/organization/projects' },
    members: { name: 'Members', path: '/organization/members' },
    'use-cases': { name: 'All use cases', path: '/use-cases' },
    'use-case-item': { name: 'use-case-name', path: 'use-case-path' },
    description: { name: 'Sales Forecasting', path: '/use-cases/description' },
    datasets: { name: 'Datasets', path: '/datasets' },
    transform: { name: 'Transform', path: '/datasets' },
    upload: { name: 'Upload', path: '/datasets/upload' },
    creating: { name: ' Creating dataset', path: '/datasets/creating' },
    profile: { name: 'Profile', path: '/profile' },
    home: { name: 'Home page', path: '/' },
    myprofile: { name: 'Edit Profile', path: '/profile/myprofile' },
    mydevices: { name: 'My Devices', path: '/profile/mydevices' },
    faq: { name: 'FAQ', path: '/faq' },
};

export const checkActiveMenuItem = (itemName: string, menu: {}) => {
    if (!pathDict[itemName] || !pathDict[itemName].path) return;
    const tempActive: { [key: string]: boolean } = {};
    for (let i in menu) {
        tempActive[i] = false;
    }
    tempActive[itemName] = true;
    return tempActive;
};
export const addZero = (number: number): string => {
    if (number < 10) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
};
type type = 'auto' | 'hidden'

export const setOverflow = (type: type) => {
    const body = document.querySelector('body');
    if (body) {
        body.style.overflow = type;
        return type;
    }

}
export const setPrompt = async (prompt: string) => {
    await addDoc(collection(db, 'prompts'), {
        prompt: prompt,
        timestamp: new Date()
    })
}
export const getUseCase = async (id: string) => {
    const docSNap = await getDoc(doc(db, 'use-cases', id));
    if (docSNap.exists()) {
        return docSNap.data() as any;
    } else {
        return null;
    }
};

export const getUseCases = async () => {
    const querySnapshot = await getDocs(collection(db, 'use-cases'));
    if (querySnapshot.docs.length > 0) {
        return querySnapshot.docs.map(el => el.data())
    }
    else {
        return [];
    }
};
