import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import './FAQ.scss';
import { faqData } from './faqData';

export default function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggleAnswer = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div>
      <div style={{ margin: '20px auto' }}>

        <div style={{ maxWidth: 1345, margin: '0 auto' }}>
          <Breadcrumbs />
          <div className='faq-title'>Frequently Asked Questions</div>
        </div>
        <div
          style={{
            background: 'linear-gradient(180deg, #E6E8FF 0%, rgba(229, 229, 229, 0) 100%)',
          }}
        >
          <div className='faq-section'>
            {faqData.length > 0 &&
              faqData.map((el, index) => (
                <div key={index}>
                  {index === 0 || faqData[index - 1].section !== el.section ? (
                    <div className='section-name'>{el.section}</div>
                  ) : null}
                    <div onClick={() => handleToggleAnswer(index)} className='faq-element'>
                        <div className={`faq-question ${expandedIndex === index ? 'active' : ''}`}>
                            <span>{el.question}</span>
                            <span className='img' />
                        </div>
                        <div
                            className={`faq-answer ${expandedIndex === index ? 'active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: el.answer }}
                        />
                    </div>
                  </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}