import React, {useEffect, useState} from "react";
import './areaCode.scss'

export default function AreaCode(props:any) {
    const [country, setCountry] = useState({name:'', dialCode:0, pos:[0,0]})

    useEffect(() => {
        setCountry(props.country)
    }, [props.country]);
    return (
        <div style={{
            ...props.style,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
        }}>
            <div className={'area-code'} style={{backgroundPosition: `${country.pos&&-country.pos[0]}px ${country.pos&&-country.pos[1]}px`}}
                 >
            </div>
        </div>
    )
}
