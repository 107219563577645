import {useEffect, useRef, useState} from "react";
import './customText.scss'

interface IProps {
    hover?: boolean
    children:any
    style?: {
        fontSize?: number
        color?: string
        fontWeight?: number | string
    }
    lineStyle?: {
        color?: string
        margin?: number | string
        size?: number
        type?: 'solid' | 'dotted' | 'dashed' | 'double' | "groove" | 'ridge' | 'hidden'
    }
}

export default function CustomText(props: IProps) {
    const [border, setBorder] = useState('2px solid black')
    const [firstRun, setFirstRun] = useState(false)
    const line = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const size = props.lineStyle?.size || 2
        const type = props.lineStyle?.type || 'solid'
        const color = props.lineStyle?.color || 'inherit'
        if (props.lineStyle) {
            setBorder(`${size}px ${type} ${color}`)
        }
    }, []);
    useEffect(() => {
        if (firstRun) {
            if (props.hover) {
                line.current?.classList.add('grow');
                line.current?.classList.remove('down');
            } else {
                line.current?.classList.add('down');
                line.current?.classList.remove('grow');
            }
        }

    }, [props.hover]);
    useEffect(() => {
        setFirstRun(true)
    }, []);

    return (
        <div>
            <div  style={{...props.style, width: 'fit-content'}}>{props.children}</div>
            <div ref={line} className={'custom-text-line'} style={{borderBottom: border, width:0}}/>
        </div>
    )
}
