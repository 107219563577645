import React from 'react';
import MainPage from './MainPage';
import {FormPage} from './FormPage';
import {Features} from './Features';
import FeaturedWork from "./FeaturedWork";
import {useLocation} from "react-router-dom";

export const Home = () => {
    const location = useLocation()
    const path = location.pathname

    return (
        <>
            <MainPage/>
            <Features/>
            <FormPage/>
            {path==='/'&&<FeaturedWork/>}
        </>
    );
};
