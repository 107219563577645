import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate} from "react-router-dom";
import './menu.scss'
import { CustomButton } from '../buttons/Button';
import { setCalendar } from '../../store/reducers/loaderReducer';
import { useAppDispatch } from '../../helpers';
// @ts-ignore
import calendarIcon from "../../images/calendar.svg";

export default function MenuPage() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [burgerOpen, setBurgerOpen] = useState(false)
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState('');
    useEffect(( ) => {
        switch(location.pathname) {
            case '/':
                setCurrentLocation('home');
                break;
            case '/use-cases':
                setCurrentLocation('usecases');
                break;
            case '/contact':
                setCurrentLocation('contact');
                break;
            case '/faq':
                setCurrentLocation('faq');
                break;
            default:
                setCurrentLocation('');
                break;
        }
    }, [location])
    const navigateTo = (path: string) => {
        setBurgerOpen(false);
        navigate(`/${path}`)
    }

    return (
        <div style={{margin: '15px 0'}}>
            <div className={'register-page-menu'} style={{justifyContent: window.screen.width > 1179 ? 'space-between' : 'center'}}>
                <div style={{margin:'0 40px', textAlign: window.screen.width > 1179 ? 'left' : 'center' }}>
                    <img onClick={()=>navigate('/')} src={window.screen.width > 1179 ? 'images/logo2.svg' : 'images/logo.svg'} alt={'logo'} width={window.screen.width > 1179 ? '' : 114}/>
                </div>
                <div className='register-page-menu-links'>
                    <div data-testid={'menu-link'} className={`register-page-menu-link ${currentLocation === 'home' ? 'active' : ''}`} onClick={()=>navigate('/')}>
                        HOME PAGE
                    </div>
                    <div className={`register-page-menu-link ${currentLocation === 'usecases' ? 'active' : ''}`} onClick={()=>navigate('/use-cases')}>
                        ALL USE CASES
                    </div>
                    <div className={`register-page-menu-link ${currentLocation === 'faq' ? 'active' : ''}`} onClick={()=>navigate('/faq')}>
                        FAQ
                    </div>
                    <div className={`register-page-menu-link ${currentLocation === 'contact' ? 'active' : ''}`} onClick={()=>navigate('/contact')}>
                        CONTACT
                    </div>
                </div>
                <div className={'schedule-btn'} style={{right: 72, zIndex: 10, top: window.screen.width > 1179 ? '' : 22}}>
                    <CustomButton onClick={() => dispatch(setCalendar(true))}>
                        <img src={calendarIcon} alt={'phone icon'} />
                        <div style={{marginLeft: 8}}>
                            SCHEDULE A CALL
                        </div>

                    </CustomButton>
                </div>
            </div>
            <div className={'register-page-menu-buttons'} style={{position:"absolute",right:20, top:24, display:"flex"}}>
                <div className={`register-page-menu-burger ${burgerOpen?'change':''}`} style={{cursor:'pointer'}} onClick={()=>setBurgerOpen(!burgerOpen)}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </div>
            {burgerOpen && (
            <div className="mobile-menu">
                            <div className={'register-page-menu-buttons'} style={{position:"absolute",right:20, top:24, display:"flex"}}>
                <div className={`register-page-menu-burger ${burgerOpen?'change':''}`} style={{cursor:'pointer'}} onClick={()=>setBurgerOpen(!burgerOpen)}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </div>
                <div className='mobile-menu-link logo'>
                    <img style={{marginTop: -15}} src={'images/logo.svg'} alt={'logo'} width={114}/>
                </div>
                <div data-testid={'menu-link'} className={`mobile-menu-link ${currentLocation === 'home' ? 'active' : ''}`} onClick={() => navigateTo('')}>
                    HOME PAGE
                </div>
                <div className={`mobile-menu-link ${currentLocation === 'usecases' ? 'active' : ''}`} onClick={()=>navigateTo('use-cases')}>ALL USE CASES</div>
                <div className={`mobile-menu-link ${currentLocation === 'faq' ? 'active' : ''}`} onClick={()=>navigateTo('faq')}>FAQ</div>
                <div className={`mobile-menu-link ${currentLocation === 'contact' ? 'active' : ''}`} onClick={()=>navigateTo('contact')}>CONTACT</div>
            </div>
            )}
        </div>
    )
}
