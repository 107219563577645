import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate();
  
  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div>
      <p className='footer-desc'>Powered by QED Software © {getYear()}.</p>
      <div className='footer-links'>
        <span className='footer-element link'>
          <a href='https://qed.pl/about-us/' className='footer-element link'>
            About us
          </a>
        </span>
        <span className='footer-element link'>
          <a style={{cursor: 'pointer'}} onClick={() => navigate('/contact')} className='footer-element link'>
            Contact
          </a>
        </span>
      </div>
    </div>
  );
};
