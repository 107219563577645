import React from "react";
import './CustomTextArea.scss'

interface IProps {
    id?: string
    label?: string
    width?: number | string
    height?: number
    placeholder?: string
    onChange?: Function
    style?:any
    resize?:boolean
    value?: string;
}

export default function CustomTextArea(props: IProps) {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };
    return (
        <div className={'textarea-container'} style={{
            ...props.style,
            position: "relative",
            width:props.width,
            background: '#FFF',
            height:props.height,
        }}>
        <div id={props.id} className='textOnInput' style={{...props.style, background:"inherit"}}>
            <textarea style={{width: '100%', height: props.height, resize:props.resize?'inherit':'none', fontFamily:'Ubuntu', fontSize:16}} className='textarea-start' name="businessChallenge" id="businessChallenge" placeholder={props.placeholder} value={props.value} onChange={handleChange}/>
            <label htmlFor='businessChallenge'>
                {props.label}
            </label>
        </div>
        </div>
    )
}

