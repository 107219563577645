import React, {useEffect, useRef, useState} from 'react';
import './CustomInput.scss'
// @ts-ignore
import eyeIcon from '../../../images/eye.svg'
// @ts-ignore
import eyeHideIcon from '../../../images/eyeHide.svg'
// @ts-ignore
import downArrow from '../../../images/down-arrow-1.svg'
import { validateAreaNumber, validatePhoneNumber} from "../../../helpers";
import SelectItem from "./SelectItem";
import AreaCode from "../areaCode/AreaCode";
import {dials} from "../areaCode/dials";
import {CustomButton} from "../../buttons/Button";

interface IProps {
    id?: string
    label?: string
    width?: number
    height?: number
    error?: boolean
    defaultValue?: string
    value?: any
    style?: any
    inputStyle?: any
    type?: 'email' | 'password' | 'text' | 'phoneNumber' | 'search' | 'select' | 'area'
    onChange?: Function
    autoComplete?: string
    rightIcon?: any
    placeholder?: string
    labelStyle?: any
    selectClick?: any
    selectItems?: Array<string>
    onBlur?: Function
    country?: any
    datatestId?: string
    disabled?: boolean
    button?: { label: string, width?: number, onClick?: Function }
}

export default function CustomInput(props: IProps) {
    const container = useRef<HTMLInputElement>(null)
    const [big, setBig] = useState(true)
    const [value, setValue] = useState('')
    const [passIcon, setPassIcon] = useState(eyeIcon)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [open, setOpen] = useState(false)
    const [country, setCountry] = useState({name: '', dialCode: '0', pos: [0, 0]})
    const [areaCodes, setAreaCodes] = useState<any>()
    const [key, setKey] = useState<any>('')

    const labelClassName = big ? 'input-label big' : 'input-label';
    const datatestId = props.datatestId || 'custom-input';
    const inputClassName = `custom-input ${props.disabled ? 'disabled' : ''}`;
    const inputType = props.type || 'text';
    const isSelectInput = props.type === 'select';

    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    const typeArea = () => {
        if (props.type === 'area') {
            return (
                <div>
                    <AreaCode
                        style={{left: 10, top: 8}}
                        country={country}
                    />
                </div>
            )
        }
    }

    const inputWithButton = () => {
        if (props.button) {
            return (
                <div data-testid={'right-button'} style={{position: "absolute", top: 15, right: 5}}>
                    <CustomButton>{props.button.label}</CustomButton>
                </div>
            )
        }
    }

    const typePass = () => {
        if (props.type === 'password') {
            return (
                <div
                    onClick={showPassF}
                    data-testid={'show-pass'}
                    style={{
                        height: height - 2,
                        width: 30,
                        position: 'absolute',
                        right: 2,
                        top: 11,
                        borderRadius: 5,
                        background: 'inherit',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={passIcon} alt={'eye icon'} style={{cursor: 'pointer'}}/>
                </div>
            )
        }
    }

    const typeRightIcon = () => {
        if (props.rightIcon) {
            return (
                <div data-testid={'right-icon'} style={{
                    height: height - 2,
                    width: 30,
                    position: "absolute",
                    right: 2,
                    top: 11,
                    borderRadius: 5,
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: props.type === 'select' ? 'pointer' : ''
                }} onClick={() => props.type === 'select' ? setOpen(!open) : null}>
                    <img src={props.rightIcon} alt={''}/>
                </div>
            )
        }
    }

    useEffect(() => {
        // @ts-ignore
        if (props.country && dials[props.country]) {
            // @ts-ignore
            setCountry(dials[props.country])
            // @ts-ignore
            setValue(dials[props.country].dialCode)

            let count = 0
            for (let key1 in dials) {

                if (key1 === props.country) {
                    setPosActive(count)
                }
                count++
            }
        } else {
            setCountry(dials.af)
        }
        setAreaCodes(dials)

    }, [props.country]);
    useEffect(() => {
        setWidth(props.width || 300)
        setHeight(props.height || 30)
        if (props.defaultValue) setValue(props.defaultValue)
        // @ts-ignore

        if (props.country && dials[props.country]) setValue(dials[props.country].dialCode)
        // @ts-ignore
    }, []);
    const setupLabel = () => {
        if (value?.length > 0) {
            setBig(false)
        }
        if (document.activeElement === container.current) setBig(false)
        if (document.activeElement !== container.current && value?.length === 0) setBig(true)
        if (props.type === 'select') {
            setBig(false)
        }
        if (props.type === 'area') {
            setBig(false)
        }
    }
    const blur = () => {
        setupLabel()
        if (props.onBlur) props.onBlur()
    }
    useEffect(() => {

        if (props.error) {
            container.current?.classList.add('error')
        } else {
            container.current?.classList.remove('error')
        }
    }, [props.error]);

    useEffect(() => {
        if (props.type === 'search') container.current?.classList.add('search')
        if (props.type === 'select') container.current?.classList.add('search')
        if (props.type === "area") {
            container.current?.classList.add('area')
        }
    }, [props.type]);
    const addHover = () => {
        container.current?.classList.add('hover')
    }

    const removeHover = () => {
        container.current?.classList.remove('hover')
    }
    const showPassF = () => {
        if (container.current?.type === 'password') {
            container.current.type = 'text'
            setPassIcon(eyeHideIcon)
        } else {
            // @ts-ignore
            container.current.type = 'password'
            setPassIcon(eyeIcon)
        }
    }
    const [posActive, setPosActive] = useState(-1)
    const areaRef = useRef<HTMLDivElement>(null)
    const arrowDown = () => {
        const keys = Object.keys(areaCodes)
        key.preventDefault()
        if (posActive < keys.length - 1) {
            setPosActive(posActive + 1)
            setCountry(areaCodes[keys[posActive + 1]])
            setValue(areaCodes[keys[posActive + 1]].dialCode)
            if (areaRef.current && posActive > 2) areaRef.current.scrollTop = (posActive - 2) * 27
        }
    }
    const arrowUp = () => {
        const keys = Object.keys(areaCodes)
        key.preventDefault()
        if (posActive > 0) {
            setPosActive(posActive - 1)
            setCountry(areaCodes[keys[posActive - 1]])
            setValue(areaCodes[keys[posActive - 1]].dialCode)
            if (areaRef.current) areaRef.current.scrollTop = (posActive - 3) * 27
        }
    }
    useEffect(() => {
        if (key.key && key.key === 'Tab' && open) {
            key.preventDefault()
        }

        if (key.key && key.key === 'ArrowDown' && open) {
            arrowDown()
        }
        if (key.key && key.key === 'ArrowUp' && open) {
            arrowUp()
        }
        if (key.key === 'Enter') {
            setOpen(false)
            setAreaCodes(dials)
        }
    }, [key]);
    useEffect(() => {
        const clickDown = (e: any) => {
            setKey(e)
        }
        if (props.type && props.type === 'area') {
            document.addEventListener('keydown', clickDown)
        }
        return () => {
            // @ts-ignore
            document.removeEventListener('keydown', clickDown)
        }
    }, [])
    useEffect(() => {
        if (key.key === 'ArrowDown' || key.key === 'ArrowUp') {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [country]);

    const areaType = (isValid: any) => {
        if (props.type === 'area') {

            if (isValid) {
                container.current?.classList.remove('error');
            } else {
                container.current?.classList.add('error');
            }

        }
    }

    const changeValue = (e: any) => {
        const inputValue = e.target.value;
        const isValid = Object.values(dials).some((dial) => dial.dialCode === inputValue);

        areaType(isValid)
        setValue(inputValue);

        if (props.type === 'phoneNumber') {
            setValue(validatePhoneNumber(inputValue));
        }
        if (props.type === 'area') {
            const tempDials: any = {};
            setValue(validateAreaNumber(inputValue));
            let count = 0;
            for (let x in dials) {
                // @ts-ignore
                if (dials[x].dialCode && dials[x].dialCode.includes(inputValue)) {
                    // @ts-ignore
                    tempDials[x] = dials[x];
                }
                // @ts-ignore
                if (dials[x].dialCode === inputValue) {
                    // @ts-ignore
                    setCountry(dials[x]);
                    count++;
                }
            }
            if (count === 0) {
                setCountry({name: '', dialCode: '', pos: [-100, -100]});
            }
            setPosActive(-1);
            setAreaCodes(tempDials);
            setTimeout(() => {
                setOpen(true);
            });
        }
        if (props.onChange) props.onChange(inputValue);
    }

    useEffect(() => {
        setupLabel()
        if (key.key === 'ArrowDown' || key.key === 'ArrowUp') {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [value]);

    const renderSelectItems = () => {
        if (props.selectItems) {
            if (props.placeholder) {
                return (<div>
                    <div className={'select-item'} style={{width: '100%'}} onClick={() =>{
                        changeValue({target:{value:''}});
                        setOpen(false)
                    }}><em>{props.placeholder}</em>
                    </div>
                    {props.selectItems.map((d, i) => (

                        <SelectItem
                            key={i}
                            active={value === d}
                            label={d}
                            onClick={(e: string) => {
                                changeValue({target: {value: e}});
                                setOpen(false);
                            }}
                        />
                    ))}
                </div>)
            }
            return props.selectItems.map((d, i) => (

                <SelectItem
                    key={i}
                    active={value === d}
                    label={d}
                    onClick={(e: string) => {
                        changeValue({target: {value: e}});
                        setOpen(false);
                    }}
                />
            ));
        }
    };

    const handleKeyPress = (e: any) => {
        if (props.type === 'area') {
            const charCode = e.which || e.keyCode;
            if (charCode < 48 || charCode > 57) {
                e.preventDefault();
            }
        }
    };

    const renderAreaCodes = () => {
        return Object.keys(areaCodes).map((d, i) => {
            // @ts-ignore
            if (dials[d].name) {
                return (
                    // @ts-ignore
                    <SelectItem
                        // @ts-ignore
                        areaCode={dials[d]}
                        key={i}
                        // @ts-ignore
                        active={value === dials[d].dialCode}
                        // @ts-ignore
                        label={dials[d].name}
                        onClick={() => {
                            // @ts-ignore
                            changeValue({target: {value: dials[d].dialCode}});
                            // @ts-ignore
                            setCountry(dials[d]);

                            setTimeout(() => {
                                setOpen(false);
                                setAreaCodes(dials);
                            });
                        }}
                    />
                );
            }
        });
    };


    return (
        <div
            style={{
                background: 'inherit',
                position: 'static',
            }}
        >
            {open && (
                <div
                    style={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: open ? 100 : 0
                    }}
                    onClick={() => setOpen(false)}
                ></div>
            )}
            <div
                style={{
                    ...props.style,
                    position: 'relative',
                    width,
                    padding: '10px 0 0',
                    background: 'inherit',
                    height,
                    zIndex: open ? 101 : 0,
                }}
            >
                {typeArea()}
                <input
                    onClick={()=>{
                        if (props.type === 'select') {
                            setOpen(!open);
                            setTimeout(() => {
                                if (areaRef.current && posActive > 2) areaRef.current.scrollTop = (posActive - 2) * 27;
                            }, 10);
                    }}}
                    data-testid={datatestId}
                    className={`${inputClassName} ${isSelectInput ? 'cursor-default' : ''}`}
                    value={value}
                    style={props.inputStyle}
                    onChange={props.type === 'select' ? () => null : changeValue}
                    ref={container}
                    autoComplete={props.autoComplete}
                    placeholder={props.placeholder}
                    type={inputType}
                    onFocus={() => setupLabel()}
                    onBlur={blur}
                    disabled={props.disabled}
                    readOnly={isSelectInput}
                    onKeyPress={(e) => handleKeyPress(e)}
                ></input>
                <p
                    data-testid={'label'}
                    className={props.disabled ? 'input-label' : labelClassName}
                    style={props.labelStyle}
                    onClick={() => container.current?.focus()}
                    onMouseEnter={addHover}
                    onMouseLeave={removeHover}
                >
                    {props.label}
                </p>
                {typePass()}
                {typeRightIcon()}
                {inputWithButton()}
                {(props.type === 'select' || props.type === 'area') && (
                    <div
                        data-testid={'select-icon'}
                        style={{
                            height: height - 2,
                            width: 30,
                            position: 'absolute',
                            right: 2,
                            top: 11,
                            borderRadius: 5,
                            background: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setOpen(!open);
                            setTimeout(() => {
                                if (areaRef.current && posActive > 2) areaRef.current.scrollTop = (posActive - 2) * 27;
                            }, 10);
                        }}
                    >
                        <img src={downArrow} alt={''}/>
                    </div>
                )}
                {props.type === 'select' && open && <div className={'select-expand'}>{renderSelectItems()}</div>}
                {props.type === 'area' && open &&
                    <div ref={areaRef} className={'select-expand area'}>{renderAreaCodes()}</div>}
            </div>
        </div>
    );
}
