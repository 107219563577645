import React from "react";
import './ContactPage.scss';
//@ts-ignore
import fbIcon from '../../images/fb.svg';
//@ts-ignore
import linkedInIcon from '../../images/linkedin.svg';
//@ts-ignore
import ytIcon from '../../images/yt.svg';
//@ts-ignore
import calendarIcon from "../../images/calendar.svg";
//@ts-ignore
import phoneIcon from '../../images/phone.svg';
//@ts-ignore
import mailIcon from '../../images/mail-blue.svg';
import { CustomButton } from "../buttons/Button";
import { useAppDispatch } from "../../helpers";
import { setCalendar } from "../../store/reducers/loaderReducer";

interface IProps {
    test?: boolean;
}

export const ContactPage = (props: IProps) => {
    const dispatch = useAppDispatch();

    return (
        <div>
            <div>
                <div style={{
                    background: 'linear-gradient(180deg, #E6E8FF 0%, rgba(229, 229, 229, 0) 100%)',
                    height: 417
                }} />
                <div className="contact-page">
                    <div className="contact-page-title">Contact us</div>
                <div className="contact-us-elements">
                    <div className="contact-info">
                        <div className="contact-element contact">
                            <div className="contact-title">Let's connect</div>
                            <div className="contact-us">
                                <a href="tel:+48510106648">
                                    <div>
                                        <img src={phoneIcon} alt="phone" />
                                        <div>+48 510 106 648</div>
                                    </div>
                                </a>
                                <a href="mailto:contact@qed.pl">
                                    <div>
                                        <img src={mailIcon} alt="mail" />
                                        <div>contact@qed.pl</div>
                                    </div>
                                </a>
                            </div>
                            <div className="contact-desc">
                                <div style={{marginBottom: 15}}><b>QED Software Sp. z o.o.</b></div>
                                <div>KRS: 0000174197</div>
                                <div>NIP: 5262370692</div>
                                <div>REGON: 014909604</div>
                            </div>
                            <div>
                                <div className="social">Check our social media:</div>
                                <div className="social-icons">
                                    <a href="https://www.facebook.com/qedsoftware/" target="_blank"><img src={fbIcon} alt="fb" /></a>
                                    <a href="https://www.linkedin.com/company/qedsoftware" target="_blank"><img src={linkedInIcon} alt="linkedin" /></a>
                                    <a href="https://www.youtube.com/@qedsoftware" target="_blank"><img src={ytIcon} alt="yt" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="contact-element meeting">
                            <div className="contact-title">Schedule the meeting</div>
                            <div className="contact-call" style={{marginLeft: 20, marginTop: 20}}>
                                <img src="/images/managerPhoto.png" width={71} height={71} alt="" />
                                <div>
                                    <div className="contact-name">Marek Kotliński</div>
                                    <div className="contact-info">Account Manager</div>
                                    <CustomButton onClick={() => dispatch(setCalendar(true))}>
                                        <img src={calendarIcon} alt={'phone icon'} />
                                        <div style={{marginLeft: 8}}>
                                            SCHEDULE A CALL
                                        </div>
                                    </CustomButton>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-element map-section">
                        <div className="contact-title">Our office</div>
                        <div className="contact-desc">
                            <div>Miedziana 3A,</div>
                            <div>00-814 Warsaw, Poland</div>
                        </div>
                        <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.8564012319684!2d20.988451077268497!3d52.227826671985994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc94a57ef21d%3A0xd311140810c5e14f!2sQED%20Software%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1699454309447!5m2!1spl!2spl" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
