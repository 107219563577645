import React from 'react';
import { Link } from 'react-router-dom';

export const Error = () => {
  return (
    <div
      className='error-page'
      style={{ backgroundImage: 'url(images/4276.svg)' }}
    >
      <img src='images/logo.svg' alt='Logo' width={121}/>
      <div className='title'>
        <div className='title-text-one'>Something went wrong</div>
        <div className='title-text-two'>Error 400</div>
      </div>
      <Link to='/'>
        <button className='get-button'>BACK TO HOME PAGE</button>
      </Link>
    </div>
  );
};
