import React, {useState} from 'react';
// @ts-ignore
import arrowRight from '../images/arrow-right.svg'
// @ts-ignore
import arrowRightDark from '../images/arrow-right-dark.svg'
import {useNavigate} from "react-router-dom";
// @ts-ignore
import diamondIcon from "../images/diamond.svg";
// @ts-ignore
import discountIcon from "../images/discount.svg";
// @ts-ignore
import supportIcon from "../images/help-desk.svg";
// @ts-ignore
import analyticIcon from "../images/analytic.svg";
// @ts-ignore
import easyIcon from '../images/easy.svg'
// @ts-ignore
import groupIcon from '../images/group.svg'
// @ts-ignore
import vectorIcon from '../images/Vector (4).svg'
// @ts-ignore
import noteIcon from '../images/g1428.svg'
// @ts-ignore
import clockIcon from '../images/clock.svg'
// @ts-ignore
import gearIcon from '../images/gear.svg'
import {CustomButton} from "./buttons/Button";



export const Features = () => {
    const navigate = useNavigate();
    const [features, setFeatures] = useState<{ [key: string]: boolean }>({
        a0: false,
        a1: false,
        a2: false,
        a3: false,
        a4: false
    })
    const hover = (name?: string) => {
        const tempFeatures = {...features}
        if (name) {
            tempFeatures[name] = true
            setFeatures(tempFeatures)
        } else {
            setFeatures({a0: false, a1: false, a2: false, a3: false, a4: false})
        }
    }
    return (
        <div className='features-container'>
            <div className='features'>
                <div className={'use-cases-container'}>
                    <div className="use-cases-title">Take a look at how AI is applied in business through these sample
                        use cases
                    </div>
                    <div className="use-cases-section">
                        <div className="use-case" onClick={() => navigate('/use-cases/UC060')}
                             onMouseEnter={() => hover('a0')} onMouseLeave={() => hover()}>
                            <img src={'images/useCases/W250/UC060_250.png'} width={226} height={103}
                                 style={{borderRadius: 4}} alt="rec1"/>
                            <div className="use-case-text" style={{marginTop: -7}}>
                                <span>Anomaly Detection in Manufacturing</span>
                                <img src={features.a0 ? arrowRightDark : arrowRight} alt="right"/>
                            </div>
                        </div>
                        <div className="use-case" onClick={() => navigate('/use-cases/UC014')}
                             onMouseEnter={() => hover('a1')} onMouseLeave={() => hover()}>
                            <img src={'images/useCases/W250/UC014_250.png'} width={226} height={103}
                                 style={{borderRadius: 4}} alt="rec2"/>
                            <div className="use-case-text">
                                <span>Image Recognition</span>
                                <img src={features.a1 ? arrowRightDark : arrowRight} alt="right"/>
                            </div>
                        </div>
                        <div className="use-case" onClick={() => navigate('/use-cases/UC033')}
                             onMouseEnter={() => hover('a2')} onMouseLeave={() => hover()}>
                            <img src={'images/useCases/W250/UC033_250.png'} width={226} height={103}
                                 style={{borderRadius: 4}} alt="rec3"/>
                            <div className="use-case-text" style={{marginTop: -7}}>
                                <span>Automated Document Classification</span>
                                <img src={features.a2 ? arrowRightDark : arrowRight} alt="right"/>
                            </div>
                        </div>
                        <div className="use-case" onClick={() => navigate('/use-cases/UC042')}
                             onMouseEnter={() => hover('a3')} onMouseLeave={() => hover()}>
                            <img src={'images/useCases/W250/UC042_250.png'} width={226} height={103}
                                 style={{borderRadius: 4}} alt="rec4"/>
                            <div className="use-case-text">
                                <span>Event Prediction</span>
                                <img src={features.a3 ? arrowRightDark : arrowRight} alt="right"/>
                            </div>
                        </div>
                        <div className="use-case" onClick={() => navigate('/use-cases/UC045')}
                             onMouseEnter={() => hover('a4')} onMouseLeave={() => hover()}>
                            <img src={'images/useCases/W250/UC045_250.png'} width={226} height={103}
                                 style={{borderRadius: 4}} alt="rec5"/>
                            <div className="use-case-text">
                                <span>Smart Waste Management</span>
                                <img src={features.a4 ? arrowRightDark : arrowRight} alt="right"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <CustomButton onClick={() => navigate('/use-cases')}>
                        SHOW ALL USE CASES
                    </CustomButton>
                </div>

                <div style={{marginTop: 100}} className="ex-promps">Get Early Access to:</div>
                <div className={'get-early-access-to-container'}>
                    <div className={'details-element'}>
                        <div>
                            <img src={easyIcon} alt={'icon'}/>
                        </div>
                        <div className={'text'} style={{width: 200}}>
                            Advanced - yet easy to use - AI service.
                        </div>
                    </div>
                    <div className={'details-element'}>
                        <div>
                            <img src={groupIcon} alt={'icon'}/>
                        </div>
                        <div className={'text'} style={{width: 300}}>
                            Tailored decision-making advice, 60+ use cases and AI templates for fast start.
                        </div>
                    </div>
                    <div className={'details-element'}>
                        <div>
                            <img src={vectorIcon} alt={'icon'}/>
                        </div>
                        <div className={'text'} style={{width: 320}}>
                            Data processing and translation of business goals into AI tasks.
                        </div>
                    </div>
                    <div className={'details-element'}>
                        <div>
                            <img src={noteIcon} alt={'icon'}/>
                        </div>
                        <div className={'text'} style={{width: 320}}>
                            Data annotation tasks for specialists.
                        </div>
                    </div>
                    <div className={'details-element'}>
                        <div>
                            <img src={clockIcon} alt={'icon'}/>
                        </div>
                        <div className={'text'}>
                            Smooth operations and real-time monitoring tools.
                        </div>
                    </div>
                    <div className={'details-element'}>
                        <div>
                            <img src={gearIcon} alt={'icon'}/>
                        </div>
                        <div className={'text'}>
                            Tracking effectiveness of AI in business contexts.
                        </div>
                    </div>
                </div>

                <div className="promps-section">
                    <div className="ex-promps">What Early Access Entails:</div>
                    <div className={'early-access-entails-container'}>
                        <div className={'details-element'}>
                            <div>
                                <img src={diamondIcon} alt={'icon'}/>
                            </div>
                            <div className={'text'} style={{width: 200}}>
                                Exclusive Content
                            </div>
                            <div className={'desc'}>
                                Early access to educational materials, webinars, podcasts and more.
                            </div>
                        </div>
                        <div className={'details-element'}>
                            <div>
                                <img src={discountIcon} alt={'icon'}/>
                            </div>
                            <div className={'text'} style={{width: 200}}>
                                Limited Time Discount
                            </div>
                            <div className={'desc'} style={{width: 300}}>
                                Get in touch and see what discount we have for you.
                            </div>
                        </div>
                        <div className={'details-element'}>
                            <div>
                                <img src={supportIcon} alt={'icon'}/>
                            </div>
                            <div className={'text'} style={{width: 200}}>
                                Personalized Support
                            </div>
                            <div className={'desc'} style={{width: 280}}>
                                Dedicated customer support and direct communication channels for early access customers.
                            </div>
                        </div>
                        <div className={'details-element'}>
                            <div>
                                <img src={analyticIcon} alt={'icon'}/>
                            </div>
                            <div className={'text'}>
                                Beta Testing Opportunities
                            </div>
                            <div className={'desc'} style={{width: 280}}>
                                The chance to provide valuable feedback and shape the final product.
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};
