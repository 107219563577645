import React from 'react';
import './App.css';

import PrivacyPolicy from './components/PrivacyPolicy';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {Home} from './components/Home';
import {Footer} from './components/Footer';
import {Error} from './components/Error';
import {SolutionsPage} from "./components/solutions/SolutionsPage";
import DescriptionPage from "./components/description/DescriptionPage";
import {UseCasesPage} from "./components/use-cases/UseCasesPage";
import {Calendar} from "./components/calendar/Calendar";
import {useSelector} from "react-redux";
import {isCalendarVisibleR, setCalendar} from "./store/reducers/loaderReducer";
import {CustomButton} from "./components/buttons/Button";
// @ts-ignore
import calendarIcon from "./images/calendar.svg";
import {useAppDispatch} from "./helpers";
import FAQ from './components/FAQ/FAQ';
import { ContactPage } from './components/ contact-page/ContactPage';
import MenuPage from './components/menu/Menu';

function App() {
    const dispatch = useAppDispatch();
    const calendar = useSelector(isCalendarVisibleR)
    return (
        <div
            className='App'
        >
            {calendar && <Calendar/>}
            <Router>
                <MenuPage />
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
                    <Route path='/faq' element={<FAQ />}/>
                    <Route path='/contact' element={<ContactPage />}/>
                    <Route path='/error' element={<Error/>}/>
                    <Route path="/suggested-solutions" element={<SolutionsPage/>}/>
                    <Route path="/use-cases/:usecaseId" element={<DescriptionPage/>}/>
                    <Route path="/use-cases" element={<UseCasesPage/>}/>
                    <Route path='*' element={<Error/>}/>
                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;
