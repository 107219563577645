export const dials:{[key:string]:{name:string, dialCode: string, pos:Array<number>}} = {
    "af": {name: "Afghanistan", dialCode: '+ 93', pos: [96, 24]},
    "al": {name: "Albania", dialCode: '+ 355', pos: [168, 24]},
    "dz": {name: "Algeria", dialCode: '+ 213', pos: [264, 96]},
    "ad": {name: "Andorra", dialCode: '+ 376', pos: [48, 24]},
    "ao": {name: "Angola", dialCode: '+ 244', pos: [240, 24]},
    "ag": {name: "Antigua i Barbuda", dialCode: '+ 1268', pos: [120, 24]},
    "ar": {name: "Argentina", dialCode: '+ 54', pos: [288, 24]},
    "am": {name: "Armenia", dialCode: '+ 374', pos: [192, 24]},
    "aw": {name: "Aruba", dialCode: '+ 297', pos: [384, 24]},
    "au": {name: "Australia", dialCode: '+ 61', pos: [360, 24]},
    "at": {name: "Austria", dialCode: '+ 43', pos: [336, 24]},
    "az": {name: "Azerbaijan", dialCode: '+ 994', pos: [24, 48]},
    "bs": {name: "Bahamas", dialCode: "+ 1242", pos: [384, 48]},
    "bh": {name: "Bahrain", dialCode: "+ 973", pos: [192, 48]},
    "bd": {name: "Bangladesh", dialCode: "+ 880", pos: [96, 48]},
    "bb": {name: "Barbados", dialCode: " + 1246", pos: [72, 48]},
    "by": {name: "Belarus", dialCode: '+ 375', pos: [48, 72]},
    "be": {name: "Belgium", dialCode: '+ 32', pos: [120, 48]},
    "bz": {name: "Belize", dialCode: '+ 501', pos: [72, 72]},
    "bj": {name: "Benin", dialCode: '+ 229', pos: [240, 48]},
    "bt": {name: "Bhutan", dialCode: "+ 975", pos: [0, 72]},
    "bo": {name: "Bolivia", dialCode: '+ 591', pos: [336, 48]},
    "ba": {name: "Bosnia and Herzegovina", dialCode: '+387', pos: [48, 48]},
    "bw": {name: "Botswana", dialCode: '+ 267', pos: [24, 72]},
    "br": {name: "Brazil", dialCode: '+ 55', pos: [360, 48]},
    "io": {name: 'British Indian Ocean Territory', dialCode: "+ 246", pos: [168, 0]},
    "bn": {name: "Brunei", dialCode: "+ 673", pos: [312, 48]},
    "bg": {name: "Bulgaria", dialCode: "+ 359", pos: [168, 48]},
    "bf": {name: "Burkina Faso", dialCode: "+ 226", pos: [144, 48]},
    "bi": {name: "Burundi", dialCode: "+ 257", pos: [216, 48]},
    "kh": {name: "Cambodia", dialCode: '+ 855', pos: [312, 168]},
    "cm": {name: "Cameroon", dialCode: '+ 237', pos: [312, 72]},
    "ca": {name: "Canada", dialCode: "+ 1", pos: [96, 72]},
    "cv": {name: "Cape Verde", dialCode: "+ 238", pos: [24, 96]},
    "bq": {name: "Caribbean Netherlands", dialCode: "+ 599", pos: [264, 240]},
    "cf": {name: "Central African R", dialCode: "+ 236", pos: [168, 72]},
    "td": {name: "Chad", dialCode: "+ 235", pos: [168, 312]},
    "cl": {name: "Chile", dialCode: "+ 56", pos: [288, 72]},
    "cn": {name: "China", dialCode: "+ 86", pos: [336, 72]},
    "co": {name: "Colombia", dialCode: "+ 57", pos: [360, 72]},
    "km": {name: "Comoros", dialCode: "+ 269", pos: [360, 168]},
    "cd": {name: "Congo", dialCode: '+ 243', pos: [144, 72]},
    "cg": {name: "Congo", dialCode: '+ 242', pos: [192, 72]},
    "cr": {name: "Costa Rica", dialCode: "+ 506", pos: [384, 72]},
    "ci": {name: "Côte d’Ivoire", dialCode: "+ 225", pos: [240, 72]},
    "hr": {name: "Croatia", dialCode: "+ 385", pos: [264, 144]},
    "cu": {name: "Cuba", dialCode: "+ 53", pos: [0, 96]},
    "cw": {name: "Curaçao", dialCode: "+ 599", pos: [48, 96]},
    "cy": {name: "Cyprus", dialCode: '+ 357', pos: [96, 96]},
    "cz": {name: "Czech Republic", dialCode: "+ 420", pos: [120, 96]},
    "dk": {name: "Denmark", dialCode: "+ 45", pos: [192, 96]},
    "dj": {name: "Djibouti", dialCode: "+ 253", pos: [168, 96]},
    "dm": {name: "Dominica", dialCode: "+ 1767", pos: [216, 96]},
    "do": {name: "Dominican Republic", dialCode: "+ 1", pos: [240, 96]},
    "ec": {name: "Ecuador", dialCode: "+ 593", pos: [288, 96]},
    "eg": {name: "Egypt", dialCode: "+ 20", pos: [336, 96]},
    "sv": {name: "Salvador", dialCode: "+ 503", pos: [72, 312]},
    "gq": {name: "Equatorial Guinea", dialCode: "+ 240", pos: [48, 144]},
    "er": {name: "Eritrea", dialCode: "+ 291", pos: [384, 96]},
    "ee": {name: "Estonia", dialCode: "+ 372", pos: [312, 96]},
    "et": {name: "Ethiopia", dialCode: "+ 251", pos: [24, 120]},
    "fj": {name: "Fiji", dialCode: "+ 679", pos: [96, 120]},
    "fi": {name: "Finland", dialCode: "+ 358", pos: [72, 120]},
    "fr": {name: "France", dialCode: "+ 33", pos: [192, 120]},
    "gf": {name: "French Guiana", dialCode: "+ 594", pos: [192, 120]},
    "pf": {name: "French Polynesia", dialCode: "+ 689", pos: [72, 264]},
    "ga": {name: "Gabon", dialCode: "+ 241", pos: [216, 120]},
    "gm": {name: "Gambia", dialCode: "+ 220", pos: [0,  144]},
    "ge": {name: "Georgia", dialCode: "+ 995", pos: [288, 120]},
    "de": {name: "Germany", dialCode: "+ 49", pos: [144, 96]},
    "gh": {name: "Ghana", dialCode: "+ 233", pos: [336, 120]},
    "gr": {name: "Greece", dialCode: "+ 30", pos: [72, 144]},
    "gd": {name: "Grenada", dialCode: "+ 1473", pos: [264, 120]},
    "gp": {name: "Guadeloupe", dialCode: "+ 590", pos: [192, 120]},
    "gu": {name: "Guam", dialCode: "+ 1671", pos: [144, 144]},
    "gt": {name: "Guatemala", dialCode: "+ 502", pos: [120, 144]},
    "gn": {name: "Guinea", dialCode: "+ 224", pos: [24, 144]},
    "gw": {name: "Guinea-Bissau", dialCode: "+ 245", pos: [168, 144]},
    "gy": {name: "Guyana", dialCode: "+ 592", pos: [192, 144]},
    "ht": {name: "Haiti", dialCode: "+ 509", pos: [288, 144]},
    "hn": {name: "Honduras", dialCode: "+ 504", pos: [240, 144]},
    "hk": {name: "Hong Kong", dialCode: "+ 852", pos: [216, 144]},
    "hu": {name: "Hungary", dialCode: "+ 36", pos: [312, 144]},
    "is": {name: "Iceland", dialCode: "+ 354", pos: [120, 168]},
    "in": {name: "India", dialCode: "+ 91", pos: [48, 168]},
    "id": {name: "Indonesia", dialCode: "+ 62", pos: [360, 144]},
    "ir": {name: "Iran", dialCode: "+ 98", pos: [96, 168]},
    "iq": {name: "Iraq", dialCode: "+ 964", pos: [72, 168]},
    "ie": {name: "Ireland", dialCode: "+ 353", pos: [384, 144]},
    "il": {name: "Israel", dialCode: "+ 972", pos: [0, 168]},
    "it": {name: "Italy", dialCode: "+ 39", pos: [144, 168]},
    "jm": {name: "Jamaica", dialCode: "+ 1876", pos: [192, 168]},
    "jp": {name: "Japan", dialCode: "+ 81", pos: [240, 168]},
    "jo": {name: "Jordan", dialCode: '+ 962', pos: [216, 168]},
    "kz": {name: "Kazakhstan", dialCode: "+ 7", pos: [96, 192]},
    "ke": {name: "Kenya", dialCode: "+ 254", pos: [264, 168]},
    "ki": {name: "Kiribati", dialCode: "+ 686", pos: [336, 168]},
    "xk": {name: "Kosovo", dialCode: "+ 383", pos: [145, 0]},
    "kw": {name: "Kuwait", dialCode: "+ 965", pos: [48, 192]},
    "kg": {name: "Kirghistan", dialCode: "+ 996", pos: [288, 168]},
    "la": {name: "Laos", dialCode: "+ 856", pos: [120, 192]},
    "lv": {name: "Latvia", dialCode: "+ 371", pos: [336, 192]},
    "lb": {name: "Lebanon", dialCode: "+ 961", pos: [144, 192]},
    "ls": {name: "Lesotho", dialCode: "+ 266", pos: [264, 192]},
    "lr": {name: "Liberia", dialCode: "+ 231", pos: [240, 192]},
    "ly": {name: "Libya", dialCode: "+ 218", pos: [360, 192]},
    "li": {name: "Liechtenstein", dialCode: '+ 423', pos: [192, 192]},
    "lt": {name: "Lithuania", dialCode: "+ 370", pos: [288, 192]},
    "lu": {name: "Luxembourg", dialCode: "+ 352", pos: [312, 192]},
    "mo": {name: "Macau", dialCode: "+ 853", pos: [240, 216]},
    "mk": {name: "Macedonia", dialCode: "+ 389", pos: [144, 216]},
    "mg": {name: "Madagascar", dialCode: "+ 261", pos: [96, 216]},
    "mw": {name: "Malawi", dialCode: "+ 265", pos: [24, 240]},
    "my": {name: "Malaysia", dialCode: "+ 60", pos: [72, 240]},
    "mv": {name: "Maldives", dialCode: "+ 960", pos: [0, 240]},
    "ml": {name: "Mali", dialCode: "+ 223", pos: [168, 216]},
    "mt": {name: "Malta", dialCode: "+ 356", pos: [360, 216]},
    "mh": {name: "Marshall Islands", dialCode: "+ 692", pos: [120, 216]},
    "mq": {name: "Martinique", dialCode: "+ 596", pos: [288, 216]},
    "mr": {name: "Mauritania", dialCode: "+ 222", pos: [312, 216]},
    "mu": {name: "Mauritius", dialCode: "+ 230", pos: [384, 216]},
    "mx": {name: "Mexico", dialCode: "+ 52", pos: [48, 240]},
    "fm": {name: "Micronesia", dialCode: "+ 691", pos: [144, 120]},
    "md": {name: "Moldova", dialCode: "+ 373", pos: [24, 216]},
    "mc": {name: "Monaco", dialCode: "+ 377", pos: [0, 216]},
    "mn": {name: "Mongolia", dialCode: "+ 976", pos: [216, 216]},
    "me": {name: "Montenegro", dialCode: "+ 382", pos: [48, 216]},
    "ma": {name: "Morocco", dialCode: "+ 212", pos: [384, 192]},
    "mz": {name: "Mozambique", dialCode: "+ 258", pos: [96, 240]},
    "mm": {name: "Myanmar", dialCode: "+ 95", pos: [192, 216]},
    "na": {name: "Namibia", dialCode: "+ 264", pos: [120, 240]},
    "nr": {name: "Nauru", dialCode: "+ 674", pos: [336,240]},
    "np": {name: "Nepal", dialCode: "+ 977", pos: [312, 240]},
    "nl": {name: "Netherlands", dialCode: "+ 31", pos: [264, 240]},
    "nc": {name: "New Caledonia", dialCode: "+ 687", pos: [144,240]},
    "nz": {name: "New Zealand", dialCode: "+ 64", pos: [384, 240]},
    "ni": {name: "Nicaragua", dialCode: "+ 505", pos: [240, 240]},
    "ne": {name: "Niger", dialCode: "+ 227", pos: [168, 240]},
    "ng": {name: "Nigeria", dialCode: "+ 234", pos: [216, 240]},
    "kp": {name:"North Korea}", dialCode:"+ 850", pos:[0, 192]},
    "no": {name: "Norway", dialCode: '+ 47', pos: [288, 240]},
    "om": {name:"Oman", dialCode:"+ 968", pos:[0, 264]},
    "pk": {name:"Pakistan", dialCode:"+ 92", pos:[192, 264]},
    "pw": {name:"Palau", dialCode:"+ 680", pos:[336,264]},
    "ps": {name:"Palestine", dialCode:"+ 970", pos:[288, 264]},
    "pa": {name:"Panama", dialCode:"+ 507", pos:[24, 264]},
    "pg": {name:"Papua New Guinea", dialCode:"+ 675", pos:[96, 264]},
    "py": {name:"Paraguay", dialCode:"+ 595", pos:[360,264]},
    "pe": {name:"Peru", dialCode:"+ 51", pos:[48, 264]},
    "ph": {name:"Philippines", dialCode:"+ 63", pos:[120, 264]},
    "pl": {name: "Poland", dialCode: "+ 48", pos: [216, 264]},
    "pt": {name:"Portugal",dialCode:"+ 351", pos:[312, 264]},
    "pr": {name:"Puerto Rico",dialCode:"+ 1", pos:[264,264]},
    "qa": {name:"Qatar",dialCode:"+ 974", pos:[384,264]},
    "re": {name:"Reunion",dialCode:"+ 262", pos:[192, 120]},
    "ro": {name:"Romania",dialCode:"+ 40", pos:[0, 288]},
    "ru": {name:"Russia",dialCode:"+ 7", pos:[48, 288]},
    "rw": {name:"Rwanda",dialCode:"+ 250", pos:[72,288]},
    "kn": {name:"Saint Kitts and Nevis",dialCode:"+ 1869", pos:[384,168]},
    "lc": {name:"Saint Lucia",dialCode:"+ 1758", pos:[168,192]},
    "vc": {name:"Saint Vincent and Grenadines",dialCode:"+ 1784", pos:[264,336]},
    "ws": {name:"Samoa",dialCode:"+ 685", pos:[24,360]},
    "sm": {name:"San Marino",dialCode:"+ 378", pos:[336,288]},
    "st": {name:"São Tomé and Príncipe",dialCode:"+ 239", pos:[48, 312]},
    "sa": {name:"Saudi Arabia",dialCode:"+ 966", pos:[96,288]},
    "sn": {name:"Senegal",dialCode:"+ 221", pos:[360, 288]},
    "rs": {name:"Serbia",dialCode:"+ 381", pos:[24,288]},
    "sc": {name:"Seychelles",dialCode:"+ 248", pos:[144, 288]},
    "sl": {name:"Sierra Leone",dialCode:"+ 232", pos:[312,288]},
    "sg": {name:"Singapore",dialCode:"+ 65", pos:[216, 288]},
    "sk": {name:"Slovakia",dialCode:"+ 421", pos:[288,288]},
    "si": {name:"Slovenia",dialCode:"+ 386", pos:[264, 288]},
    "sb": {name:"Salomon Islands",dialCode:"+ 677", pos:[120,288]},
    "so": {name:"Somalia",dialCode:"+ 252", pos:[384,288]},
    "za": {name:"South Africa",dialCode:"+ 27", pos:[95,360]},
    "kr": {name:"South Korea",dialCode:"+ 82", pos:[24, 192]},
    "ss": {name:"Sudan",dialCode:"+ 211", pos:[24, 312]},
    "es": {name:"Spain",dialCode:"+ 34", pos:[0, 120]},
    "lk": {name:"Sri Lanka",dialCode:"+ 94", pos:[216, 192]},
    "sd": {name:"Sudan",dialCode:"+ 249", pos:[168,288]},
    "sr": {name:"Suriname",dialCode:"+ 597", pos:[0, 312]},
    "sz": {name:"Swaziland",dialCode:"+ 268", pos:[120, 312]},
    "se": {name:"Sweden",dialCode:"+ 46", pos:[192,288]},
    "ch": {name:"Switzerland",dialCode:"+ 41", pos:[216,72]},
    "sy": {name:"Syria",dialCode:"+ 963", pos:[96,312]},
    "tw": {name:"Taiwan",dialCode:"+ 886", pos:[72,336]},
    "tj": {name:"Tajikistan",dialCode:"+ 992", pos:[263,312]},
    "tz": {name:"Tanzania",dialCode:"+ 255", pos:[96,336]},
    "th": {name:"Thailand",dialCode:"+ 66", pos:[240, 312]},
    "tl": {name:"Timor-Leste",dialCode:"+ 670", pos:[312,312]},
    "tg": {name:"Togo",dialCode:"+ 228", pos:[216,312]},
    "to": {name:"Tonga",dialCode:"+ 676", pos:[384,312]},
    "tt": {name:"Trinidad and Tobago",dialCode:"+ 1868", pos:[24,336]},
    "tn": {name:"Tunisia",dialCode:"+ 216", pos:[360, 312]},
    "tr": {name:"Turkey",dialCode:"+ 90", pos:[0, 336]},
    "tm": {name:"Turkmenistan",dialCode:"+ 993", pos:[336,312]},
    "tv": {name:"Tuvalu",dialCode:"+ 688", pos:[48,336]},
    "ug": {name:"Uganda",dialCode:"+ 256", pos:[144,336]},
    "ua": {name:"Ukraine",dialCode:"+ 380", pos:[120,336]},
    "ae": {name:"United Arab Emirates",dialCode:"+ 971", pos:[72,24]},
    "gb": {name:"United Kingdom",dialCode:"+ 44", pos:[240,120]},
    "us": {name:"United States",dialCode:"+ 1", pos:[168,336]},
    "uy": {name:"Uruguay",dialCode:"+ 598", pos:[192,336]},
    "uz": {name:"Uzbekistan",dialCode:"+ 998", pos:[216,336]},
    "vu": {name:"Vanuatu",dialCode:"+ 678", pos:[384,336]},
    "va": {name:"Vatican",dialCode:"+ 379", pos:[240,336]},
    "ve": {name:"Venezuela",dialCode:"+58 ", pos:[288,336]},
    "vn": {name:"Vietnam",dialCode:"+ 84", pos:[360,336]},
    "ye": {name:"Yemen",dialCode:"+ 967", pos:[50,360]},
    "zm": {name:"Zambia",dialCode:"+ 260", pos:[120,360]},
    "zw": {name:"Zimbabwe",dialCode:"+ 263", pos:[144,360]},
}
