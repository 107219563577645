import { ISector, IUseCase, sectors, useCases } from './data';

interface ICases {
    especially: Array<IUseCase>;
    sector: Array<IUseCase>;
    other: Array<IUseCase>;
    sectorName: string;
    keywords: Array<string>;
}

export const searchKeywords = (text: string, sector: ISector): ISector => {
    let qty = 0;
    const keywords: Array<string> = [];
    for (let keyword of sector.keywords) {
        const pattern = `\\b(${keyword})(s|es)?\\b`;
        const reg = new RegExp(pattern, 'gi');
        if (text.match(reg)) {
            qty++;
            const tempKeyword = keyword.replace(/\\/g, '');
            keywords.push(tempKeyword);
        }
    }
    return { name: sector.name, qty, keywords };
};

export const fittedSectors = (text: string): Array<ISector> => {
    const fitted: Array<ISector> = [];
    for (let sector of sectors) {
        const tempSector: ISector = searchKeywords(text, sector);
        if (tempSector.qty > 0) fitted.push(tempSector);
    }
    const array = [...fitted].sort((a: any, b: any) => {
        return a.qty - b.qty;
    });
    return array.reverse();
};

const fittedUseCase = (sector: ISector): ICases => {
    const useCasesEspecially: Array<IUseCase> = [];
    const useCasesSector: Array<IUseCase> = [];
    const useCasesOther: Array<IUseCase> = [];
    for (let useCase of useCases) {
        if (useCase.especially.includes(sector.name)) {
            useCasesEspecially.push(useCase);
        }
        if (useCase.sectors.includes(sector.name)) {
            useCasesSector.push(useCase);
        }
        if (!useCase.sectors.includes(sector.name) && !useCase.especially.includes(sector.name)) {
            useCasesOther.push(useCase);
        }
    }

    return {
        sectorName: sector.name,
        especially: useCasesEspecially,
        sector: useCasesSector,
        other: useCasesOther,
        keywords: sector.keywords,
    };
};

export const fittedUseCases = (text: string, numberOfUseCases?: number):Array<IUseCase> => {
    const sectors1 = fittedSectors(text);
    const allUseCases: any = [];
    let n = numberOfUseCases || 5;
    for (let sector of sectors1) {
        allUseCases.push(fittedUseCase(sector));
    }
    const copy = JSON.stringify(allUseCases);
    const tempCases: any = JSON.parse(copy);
    const arr: any = [];

    console.log('fitted sectors', allUseCases);
    const checkAndPush = (element:Array<any>, array:Array<any>) => {
        let count = 0
        for (let d of array) {
            if (d.name === element[0].name) {
                count++
            }
        }
        if (count === 0) {
            array.push(element[0])
            element.shift()
        }
    }
    while ((arr.length < n) && allUseCases.length > 0) {
        for (let uc of tempCases) {
            if (uc.especially[0] && arr.length < n) {
                checkAndPush(uc.especially, arr)
            }
            if (!uc.especially[0] && arr.length < n && uc.sector[0]) {
                checkAndPush(uc.sector, arr)
            }
            if (!uc.especially[0]&& !uc.sector[0]&&arr.length < n) {
                checkAndPush(uc.other, arr)
            }
        }
    }
    return arr;
};
