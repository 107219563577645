import React from 'react';
import {Button} from "@mui/material";

interface IProps {
    width?:number
    disabled?:boolean
    height?:number
    fontSize?:number
    onClick?:any
    children?:any
    bgColor?:string
    hoverBgColor?: string
    margin?:string|number
    className?:string
}
export const CustomButton = (props:IProps) => {
    const style: object = {
        color: 'var(--white)',
        width: props.width || 'auto',
        background: props.bgColor || 'linear-gradient(180deg, #FF8B70 0%, #F34115 100%)',
        textTransform: "none",
        font: ' normal normal 500 14px/16px Ubuntu',
        height: props.height || 30,
        letterSpacing: 'normal',
        fontFamily: 'Ubuntu, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        margin: props.margin || 0,
        padding:'6px 20px',
        fontSize: props.fontSize||14,
        '&:hover': {
            background: props.hoverBgColor || 'linear-gradient(180deg, #ED7F66 0%, #CF3711 100%)'
        },
        '&:disabled': {
            opacity: '30%',
            color: 'var(--white)'
        },

    }
    return (<Button sx={style} className={props.className} disabled={props.disabled} onClick={props.onClick}>{props.children}</Button>)
}
