import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../store';

const initialState: { loaderR: boolean, isCalendarVisibleR:boolean } = {
    loaderR: false,
    isCalendarVisibleR:false
};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (state, data: PayloadAction<boolean>) => {
            state.loaderR = data.payload
        },
        setCalendar:(state, data: PayloadAction<boolean>) => {
            document.body.style.overflow = data.payload ? 'hidden' : 'auto';
            state.isCalendarVisibleR = data.payload
        },
    }
})

export const {setLoader, setCalendar} = loaderSlice.actions
export const loaderR = (state: RootState) => state.loader.loaderR
export const isCalendarVisibleR = (state: RootState) => state.loader.isCalendarVisibleR

export default loaderSlice.reducer
