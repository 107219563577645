// @ts-ignore
import gazeta from '../images/gazeta.svg';
// @ts-ignore
import rzeczpospolita from '../images/rzeczpospolita.svg';
// @ts-ignore
import tvp from '../images/tvp1.svg';
// @ts-ignore
import forbes from '../images/forbes1.svg';
import './FeaturedWorks.scss'
export default function FeaturedWork() {
    return(
        <div>
            <div className="featured-title">Our work was featured on</div>
            <div>
                <img className="featured-element" src={gazeta} alt="gazeta" />
                <img className="featured-element" src={rzeczpospolita} alt="rzeczpospolita" />
                <img className="featured-element" src={tvp} alt="tvp" width={170} />
                <img className="featured-element" src={forbes} alt="forbes" width={170} />
            </div>
        </div>
    )
}
