import React, {useEffect, useState} from "react";
import './UseCasesPage.scss';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import {useNavigate} from "react-router-dom";
import CustomInput from "../../components/inputs/input/CustomInput";
// @ts-ignore
import Zoom from '../../images/zoom-in.svg';
import {collection, getDocs} from 'firebase/firestore';
import {db} from "../../firebaseConfig";

import {useSelector} from "react-redux";
import {loaderR, setLoader} from "../../store/reducers/loaderReducer";
import {useAppDispatch} from "../../helpers";
import Loader from "../loader/Loader";
import {sectors} from "../naiveModel/data";


interface IProps {
    test?: boolean;
}

export const UseCasesPage = (props: IProps) => {
    const [items, setItems] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchSelect, setSearchSelect] = useState('');
    const loader = useSelector(loaderR)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [sectorItems, setSectorItems] = useState([''])
    const [filtered, setFiltered] = useState<Array<any>>([])
    useEffect(() => {
        const arr = []
        setSearchSelect('')
        for (let sector of sectors) {
            arr.push(sector.name)
        }
        setSectorItems(arr)
    }, []);
    const getUseCases = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'use-cases'));
            const tempItems: any = [];
            querySnapshot.forEach((doc) => {
                tempItems.push(doc.data());
            });
            setItems(tempItems);
            setFiltered(tempItems)
            dispatch(setLoader(false))
        } catch (error) {
            setLoader(false)
            console.error('Firebase error: ', error);
        }
    };
    useEffect(() => {
        setFiltered(items)
    }, [items]);
    useEffect(() => {
        dispatch(setLoader(true))
        if (props.test) {
            FilteredUseCases([{name: 'abc', description: ''}], 'ab');
            FilteredUseCases([{name: 'abc'}], 'dd');
            FilteredUseCases([], 'dd');
            FilteredUseCases([{name: 'aa', description: 'bc'}], 'bc');
        }
        getUseCases().finally(() => dispatch(setLoader(false)))
    }, []);

    const FilteredUseCases = (list: any[], filter: string) => {
        const filtered: any[] = [];
        list?.map((el: any) => {
            return ((el.name?.toLowerCase().includes(filter.toLowerCase()) || el.description?.toLowerCase().includes(filter.toLowerCase())) &&
                filtered.push(el)
            )
        });
        setFiltered(filtered);
    };
    useEffect(() => {
        const filter = filteredBySector(searchSelect)
        if (searchSelect !== ''){
            FilteredUseCases(filter, searchInput)
        } else {
            FilteredUseCases(items, searchInput)
        }
    }, [searchInput]);
    const filteredBySector = (text: string) => {
        const filteredS: any[] = [];
        items?.map((el: any) => {
            if((el.sectors.includes(text))) {
                filteredS.push(el)
            }
        });
        items?.map((el:any) => {
            if ((el.sectors.length === 0)) {
                filteredS.push(el)
            }
        })
        setFiltered(filteredS);
        return filteredS
    }

    const searchSector = (e: string) => {
        setSearchInput('')

        setSearchSelect(e)
       if (e !== '') {
           filteredBySector(e)
       } else {
           setFiltered(items)
       }
    }
    const searchUseCases = (e: string) => {
        setSearchInput(e)
    };

    return (
        <div>
            <Loader open={loader}/>

            <div className="use-case-page">
                <div style={{
                    background: 'linear-gradient(180deg, #E6E8FF 0%, rgba(229, 229, 229, 0) 100%)',
                    height: 417
                }}/>
                <div className="use-case-section">
                    <Breadcrumbs/>
                    <div className="use-case-title-section">
                        <span
                            className="use-case-title">{searchInput === '' ? 'All use cases' : 'Searching result'}</span>
                        <div className="use-case-inputs">
                            <CustomInput id="use-case-select" type="select" selectItems={sectorItems} width={199}
                                         height={30} inputStyle={{background: 'var(--white)'}} value={''}
                                         style={{marginRight: 30}} onChange={searchSector} placeholder={'Select sector'}
                            />

                            <CustomInput width={199} height={30} placeholder="Search use case"
                                         inputStyle={{background: 'var(--white)'}} rightIcon={Zoom}
                                         onChange={searchUseCases} value={searchInput}/>
                        </div>
                    </div>
                    <div className="use-cases">
                        {items.length > 0 && (
                            filtered.map((item, index) => {
                                const desc = item.content.description[0]
                                return (
                                    <div key={index}>
                                        <div key={`use-case-${item.id}`} id={`use-case-${item.id}`}
                                             className="use-case-element"
                                             onClick={() => navigate(`/use-cases/${item.id}`)}>
                                            <img src={`images/useCases/W250/${item.id}_250.png`} alt="" className="use-case-image"/>
                                            <div className="title">{item.name}</div>
                                            <div className="description">{desc}</div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
