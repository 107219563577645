import React from 'react';
// @ts-ignore
import warning from '../../images/warging.svg';

import './Calendar.scss';
import {useAppDispatch} from "../../helpers";
import {setCalendar} from "../../store/reducers/loaderReducer";
// @ts-ignore
import crossIcon from '../../images/cross.svg'
export const Calendar = () => {
    const dispatch = useAppDispatch();

    return (
        <div>

                <div className="modal-overlay" onClick={() => dispatch(setCalendar(false))}>
                    <div className="modal">
                        <button className="close-button" onClick={() => dispatch(setCalendar(false))}>
                            <img src={crossIcon} alt={'cross icon'} style={{cursor:"pointer"}}/>
                        </button>
                        <iframe
                            data-testid="modal-meeting"
                            className="modal-meeting"
                            src="https://meetings-eu1.hubspot.com/marek-kotlinski"
                        />
                    </div>
                </div>

        </div>
    );
};
