export const faqData = [
    {
        section: 'Getting Started',
        question: 'What is BlueQuail?',
        answer: 'BlueQuail is a user-friendly AI platform that allows non-technical individuals and teams to create, deploy, and manage AI solutions without any coding knowledge. These solutions could be customized to your business needs to give you intelligent recommendations, scenarios, and examples to support your decision-making process. BlueQuail uses a no-code/low-code approach to make AI accessible to everyone.'
    },
    {
        section: 'Getting Started',
        question: 'Who can use BlueQuail?',
        answer: 'Anyone! BlueQuail is designed for business analysts, marketers, entrepreneurs, educators, and anyone else who wants to leverage the power of AI without the complexity of programming.'
    },
    {
        section: 'Getting Started',
        question: 'Do I need any technical skills to use BlueQuail?',
        answer: 'No technical skills are required. Our platform is designed to be intuitive and easy to use, with pre-built templates to guide you through creating your AI project.'
    },
    {
        section: 'Features',
        question: 'What can I build with BlueQuail?',
        answer: 'You can build a wide variety of AI solutions, including image recognition systems, data analysis tools, predictive models, and much more. The platform supports various AI capabilities depending on your needs.'
    },
    {
        section: 'Features',
        question: 'Does BlueQuail offer templates or pre-built components?',
        answer: 'Yes, we offer a library of templates and pre-built components that you can use as a starting point for your AI projects. This helps you get up and running quickly, with minimal setup required.'
    },
    {
        section: 'Usage',
        question: 'Is there a tutorial or guide to help me get started?',
        answer: 'Absolutely! We provide comprehensive tutorials, documentation, and user guides that walk you through every step of the process. We also offer video tutorials and a knowledge base for common questions.'
    },
    {
        section: 'Usage',
        question: 'How do I import my data into BlueQuail?',
        answer: `Importing your data into BlueQuail is a straightforward process and can be done with just a few clicks. Here's how you can get started:
            <br />
            1.   CSV Uploads:  <br />
            - You can upload up to 5 CSV files directly.<br />
            <br />
            2.   Archives Upload:  <br />
            - If you have multiple files, you can upload them as an archive. BlueQuail supports several archive formats including '.zip', '.tgz', '.rar', and '.7z'.<br />
            - Currently, the content of these archives can include:<br />
                - Only CSV files,<br />
                - Only images,<br />
                - Only videos,<br />
                - A combination of images and CSV files,<br />
                - A combination of videos and CSV files.<br />
            - Please ensure the total uncompressed size of the archive does not exceed 100GB for successful uploads.<br />
            <br />
            3.   S3 Bucket Path:  <br />
            - For larger datasets or for a more automated approach, you can use Amazon S3. Simply provide the S3 path to your public bucket. This is particularly useful if you're working with bulk data transfers.<br />
            <br />
            For any specific instructions related to data formatting or troubleshooting, please refer to our detailed documentation or reach out to our support team. We're here to help make your data import process as smooth as possible!<br />
            `
    },
    {
        section: 'Support',
        question: 'What if I need help or have questions?',
        answer: 'Our support team is here to help! You can reach out via our support chat, email, or phone.'
    },
    {
        section: 'Support',
        question: 'Are there any resources for advanced learning?',
        answer: 'Yes, for users who wish to expand their knowledge, we offer webinars, advanced tutorials, and one-on-one coaching sessions.'
    },
    {
        section: 'Pricing',
        question: 'How much does BlueQuail cost?',
        answer: 'We offer a range of pricing plans to suit different needs and budgets, including a free plan for beginners. Please visit our pricing page for detailed information on each plan.'
    },
    {
        section: 'Pricing',
        question: 'Is there a free trial available?',
        answer: `Yes, we offer a free trial to all our new users! Our trial is designed to give you full access to the platform and its features, allowing you to experience the value of our service firsthand.<br />
        <br />
        Here's how it works:<br />
        <br />
        - Trial Limit: The free trial provides you with a credit of $100 to use across our services.<br />
        - Time Frame: The trial is valid for up to 1 month from the time of activation.<br />
        - Usage-Based Completion: The trial ends either when the $100 credit is fully utilized or when the one-month period is up, whichever occurs first.<br />
        <br />
        This approach gives you the flexibility to explore our services extensively and understand how they can best serve your needs. Whether you're a small business owner, a marketer, or a data analyst, you can tailor the trial to your project's specific requirements.<br />
        <br />
        To get started, simply sign up, and the trial credits will be added to your account automatically. If you have any questions or need assistance during your trial, our customer support team is ready to help you make the most out of your BlueQuail experience.<br />
        `
    },
    {
        section: 'Security and Privacy',
        question: 'Is my data safe with BlueQuail?',
        answer: 'Protecting your data is our top priority. We implement industry-standard security measures, including encryption and regular security audits, to ensure your data is secure.'
    },
    {
        section: 'Security and Privacy',
        question: 'What is your privacy policy?',
        answer: 'We are committed to your privacy. Our privacy policy details how we collect, use, and protect your information. You can read our full privacy policy on our website.'
    },
    {
        section: 'Feedback and Updates',
        question: 'How can I provide feedback or suggest a feature?',
        answer: 'We love hearing from our users! You can provide feedback or suggest new features by contacting our support team directly.'
    },
    {
        section: 'Feedback and Updates',
        question: 'How often does BlueQuail release updates?',
        answer: 'We regularly release updates to improve the platform, add new features, and enhance the user experience. Updates are usually rolled out every few months, and we provide release notes with each update.'
    },
]