import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {setPrompt, useAppDispatch} from "../helpers";
import {fittedUseCases} from "./naiveModel/useCases";
import {setFittedUseCases, setPrompt as setPromptLocal} from "../store/reducers/useCasesReducer";
import CustomTextArea from "./textArea/CustomTextArea";
import {setLoader} from "../store/reducers/loaderReducer";
// @ts-ignore
import arrow from '../images/get-advice-arrow.svg'
import { CustomButton } from './buttons/Button';
import CookieConsent from 'react-cookie-consent';

export default function MainPage() {
    let promp1 =
        'As a 15-year-old cybersecurity firm for SMEs, we aim to boost our market presence without compromising our personalized, quality service.';
    let promp2 = 'As an electric car company, we aim to increase production and distribution.';
    let promp3 =
        "We're an 18-year-old chain of boutique hotels looking to expand while maintaining our relationships with local communities and preserving cultural authenticity.";
    let promp4 = "We are a chain of 20 organic grocery stores, operating for 10 years. We source locally and serve diverse customers seeking healthier food options. Our goal is to grow our market share without compromising our commitment to local farmers and quality"
    const [selectedPrompt, setSelectedPrompt] = useState('');
    const navigate = useNavigate();
    const handlePromptClick = (promptText: string) => {
        setSelectedPrompt(promptText);
    };
    const dispatch = useAppDispatch();
    const getAdvice = async () => {
        dispatch(setPromptLocal(''))
        if (selectedPrompt.length > 0) {
            setPrompt(selectedPrompt)
            dispatch(setPromptLocal(selectedPrompt))
        }
        const fit = fittedUseCases(selectedPrompt, 5);
        dispatch(setFittedUseCases(fit));
        dispatch(setLoader(true))
        navigate(`/suggested-solutions`);
    };
    return (
        <div
            className='mainpage'
            style={{backgroundImage: 'url(images/4276.svg)'}}
        >  
            <CookieConsent 
                buttonStyle={{ 
                    background: 'linear-gradient(180deg, #FF8B70 0%, #F34115 100%)', 
                    color: 'var(--white)', 
                    fontSize: "13px",
                    borderRadius: '5px',
                }}
                containerClasses="cookies"
                style={{
                    width: 'auto',
                    background: 'var(--white)', 
                    color: 'var(--dark-grey)', 
                    boxShadow: 'rgba(0,0,0,.19) 0 10px 20px, rgba(0,0,0,.23) 0 6px 6px',
                    borderRadius: '20px',
                    margin: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                    alignSelf: 'end',
                    left: 'auto',
                }}
                contentStyle={{
                    flex: '0',
                }}
                >
                    To provide you with the best possible experience, we use technologies, such as cookies, to store and/or access information about your device. Consenting to these technologies will allow us to process data, such as browsing behavior or unique identifiers on this site. Failure to consent or withdrawal of consent may adversely affect certain features and functions.
            </CookieConsent>
            <div className='title'>
                <div className='title-text-two'>Solve your business problems with AI</div>
                <div className='title-large'>No expertise needed</div>
                <div className='title-text-one'>BlueQuail platform is where you solve your business problems with AI. To
                    get to the solution in just 3 steps. You don't need to be an AI specialist/engineer, you don't need
                    to know ML, the platform is made for everyone.
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", marginTop: 50}}>
                <CustomTextArea
                    height={167}
                    width={740}
                    style={{background: '#fff'}}
                    label="WHAT IS YOUR BUSINESS CHALLENGE?"
                    placeholder="Please describe your case or choose one of the sample prompts below"
                    value={selectedPrompt}
                    onChange={handlePromptClick}
                />
            </div>
            <CustomButton className={'get-advice-btn'} height={50} width={735} onClick={getAdvice} fontSize={20} margin={2}>GET ADVICE <img style={{marginLeft: 10}} src={arrow} alt='' /></CustomButton>
            <div className="promps-section">
                <div className="ex-promps">Example prompts</div>
                <p
                    data-testid={'promp'}
                    className="promp"
                    onClick={() => handlePromptClick(promp1)}
                    style={{cursor: 'pointer'}}
                >
                    {promp1}
                </p>
                <p
                    data-testid={'promp'}
                    className="promp"
                    onClick={() => handlePromptClick(promp4)}
                    style={{cursor: 'pointer'}}
                >
                    {promp4}
                </p>
                <p
                    data-testid={'promp'}
                    className="promp"
                    onClick={() => handlePromptClick(promp2)}
                    style={{cursor: 'pointer'}}
                >
                    {promp2}
                </p>
                <p
                    data-testid={'promp'}
                    className="promp"
                    onClick={() => handlePromptClick(promp3)}
                    style={{cursor: 'pointer'}}
                >
                    {promp3}
                </p>
            </div>
        </div>
    );
}
